@import url('https://fonts.cdnfonts.com/css/interstate-2');

#icon {
  width: 171px;
  /*height: 76px;*/
  margin: auto;
  vertical-align: middle;
}

.m-top-30{
	margin-top: 30px;
}

.dis-inline{
	display: inline;
}
.a-block{
	display: inline-block;
	width: 100%;
}

.pos-relative{position: relative !important;}

.btn-purple, .btn-purple:focus, .btn-purple:hover, .btn-purple:active, .btn-purple:active:focus {
	background: #AA00FF;
	border-color: #AA00FF;
	color: #fff;
}

.full-width{
	width: 100%;
}
.us-txt{
	-webkit-user-select: none; /* Chrome/Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */
}

.pos-realtive{
	position: relative;
}

a.core-block{
	display: inline-block;
	width: 100%;
}

button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.img-responsive-h{
	max-height: 100% !important;
	margin: auto;
}

.ro_pic .img-responsive-h{
	max-height: 46px !important;
}

.navbar .make-active {
  font-weight: bold;
}

.gallery-container {
  cursor: pointer;
}

.directive-image {
  width: 100%;
  height: auto;
}

.cursor {
  cursor: pointer;
}

.gallery-image-single {
  position: relative;
}

.gallery-image-single .delete-icon {
  position: absolute;
  bottom: 30px;
  right: 50%;
  display: none;
}

.gallery-image-single:hover .delete-icon {
  display: block;
}

/* ANIMATE */
.fade {
	/*position: relative;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;*/
	opacity: 1;
}

.fade.ng-enter
,.fade.ng-leave {
	-webkit-transition: all 1s ease;
	transition: all 1s ease;    
	-webkit-animation: fadeInUp .5s;
	animation: fadeInUp .5s;    
}
.fade.ng-enter, .fade.ng-prepare {
	opacity: 0;
}
.fade.ng-enter-active {
	opacity: 1;
}
.fade.ng-leave {
	opacity: 1;
}
.fade.ng-leave-active {
	opacity: 0;
}

.slide {
	/*position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;*/
}

.slide.ng-enter,
.slide.ng-leave {
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}
.slide.ng-enter {
	left: 100%;
}
.slide.ng-enter-active {
	left: 0;
}
.slide.ng-leave {
	left: 0;
}
.slide.ng-leave-active {
	left: -100%;
}

.slidedown {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.slidedown.ng-enter,
.slidedown.ng-leave {
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}
.slidedown.ng-enter {
	top: -100%;
}
.slidedown.ng-enter-active {
	top: 0;
}
.slidedown.ng-leave {
	top: 0;
}
.slidedown.ng-leave-active {
	top: 100%;
}


.slideup {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
}
.slideup.ng-enter,
.slideup.ng-leave {
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}
.slideup.ng-enter {
	top: 100%;
}
.slideup.ng-enter-active {
	top: 0;
}
.slideup.ng-leave {
	top: 0;
}
.slideup.ng-leave-active {
	top: -100%;
}


@-webkit-keyframes fadeInUp {
  0% {
	opacity: 0;
	-webkit-transform: translateY(15px);
  }
  100% {
	opacity: 1;
	-webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
	opacity: 0;
	-moz-transform: translateY(15px);
  }
  100% {
	opacity: 1;
	-moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
	opacity: 0;
	-o-transform: translateY(15px);
  }
  100% {
	opacity: 1;
	-o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
	opacity: 0;
	transform: translateY(15px);
  }
  100% {
	opacity: 1;
	transform: translateY(0);
  }
}

/* ANIMATE */
.open > .dropdown-menu{
  /*display: none;*/
}

.isloading{  
  background: url('../img/loading.gif');
  background-repeat: no-repeat;
  background-position: center center; 
  height: calc(100vh - 85px);
  height: -moz-calc(100vh - 85px);
  height: -webkit-calc(100vh - 85px);
  min-height: 300px;
  width: 100%; 
  float: left; 
  /*background: #FFF;
  border:1px solid #dfe2e4;
  border-top:none;
  border-radius:5px;*/
}

.ancontainer{
  min-height: calc(100vh - 85px);
  min-height: -moz-calc(100vh - 85px);
  min-height: -webkit-calc(100vh - 85px);
}

li.subactive a, li.subactive a span {
	color: #8D00D4;
	border-color: #8D00D4;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}



.autocompletedemoCustomTemplate .autocomplete-custom-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal; }
.autocompletedemoCustomTemplate .autocomplete-custom-template li:last-child {
  border-bottom-width: 0; }
.autocompletedemoCustomTemplate .autocomplete-custom-template .item-title,
.autocompletedemoCustomTemplate .autocomplete-custom-template .item-metadata {
  display: block;
  line-height: 2; }
.autocompletedemoCustomTemplate .autocomplete-custom-template .item-title md-icon {
  height: 18px;
  width: 18px; }

  .md-virtual-repeat-container.md-autocomplete-suggestions-container{
	z-index: 100000;
  }

  md-content.globalsearch{
	margin-top: -10px;
	background: transparent;
	overflow: hidden;
  }
  md-content.globalsearch md-autocomplete{
	background: transparent; 
	overflow: hidden;
	margin:0 10px;
	width: 92%;
  }

  md-content.globalsearch md-autocomplete-wrap.md-menu-showing{
	background: rgb(250, 250, 250) none repeat scroll 0 0;
  }

  .md-virtual-repeat-container.md-autocomplete-suggestions-container{
	box-shadow: 0 5px 6px rgba(0, 0, 0, 0.25);
  }
 
  body.md-toast-animating{
	overflow: auto !important; 
  }

  md-content.globalsearch button{
	display: none;
	overflow: hidden;
  }

  md-content.globalsearch input {
	font-size: 18px;
  }

  md-toast.notify div.md-toast-content{
	height: auto;
	background: #FFF;
	padding: 8px;
	width: 300px;
	overflow: visible;  
	display: table;  
  }

  .md-center {
	  /*left: -85px;*/
	  margin: 0      
  }

  md-toast.notify.md-top{
	left: calc(50% - 158px);
	top: 25px;       
  }

  md-toast.notify h4{
	color: #333;
	margin: 0;
	cursor: pointer;
	float: right;
  }

  /*md-toast.notify div.congratulationpopupimg{
	padding-right: 10px;
  }*/

  md-toast.notify div.congratulationpopuptext{
	width: 64%
  }

  md-tooltip.blktool, md-tooltip.blktool div{
	font-size: 11px; 
	background-color: #d7d7d7;    
  }

  .md-fixed{
	position: fixed !important;
  }
  
  .md-top.md-right.md-fixed{
	top: 70px;
  }

  .md-smokedetail .md-toast-content{
	background-color: #FFC412;
	color: #000;
	text-align: center;  
  }

.desired-quantity-na-toast{
	top: 33px !important;
}

.desired-quantity-na-toast .md-toast-content {
	height: auto;
	background: rgba(50,50,50,.95);
	padding: 8px;
	width: 300px;
	overflow: visible;
	display: table;
}


.desired-quantity-na-toast .-icon {
	font-size: 30px;
	margin-right: 10px;
}
.desired-quantity-na-toast .-text {
	color: #fff;
	text-align: left;
	font-size: 12px;
	font-weight: 600;
	padding: 6px;
}
.desired-quantity-na-toast .md-toast-content h4{
	  position: absolute;
	right: 8px;
	top: -8px;
}
md-toast.desired-quantity-na-toast:before {
	content: "";
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-bottom: 8px solid rgba(50,50,50,.95);
	position: absolute;
	top: 0px;
}




.promo-notqualified-toast{
	top: 33px !important;
}

.promo-notqualified-toast .md-toast-content {
	height: auto;
	background: rgba(50,50,50,.95);
	padding: 8px;
	width: 300px;
	overflow: visible;
	display: table;
}


.promo-notqualified-toast .-icon {
	font-size: 30px;
	margin-right: 10px;
}
.promo-notqualified-toast .-text {
	color: #fff;
	text-align: left;
	font-size: 12px;
	font-weight: 600;
	padding: 6px;
}
.promo-notqualified-toast .md-toast-content h4{
	  position: absolute;
	right: 8px;
	top: -8px;
}
md-toast.promo-notqualified-toast:before {
	content: "";
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-bottom: 8px solid rgba(50,50,50,.95);
	position: absolute;
	top: 0px;
}


.cart-table td{
	position: relative;
	
}

.cart-alert{
  content: "!";
	position: absolute;
	top: 10px;
	left: 0;
	width: 26px;
	height: 26px;
	color: #fff;
	font-size: 18px;
	line-height: 25px;
	font-weight: 700;
	-webkit-border-radius: 20px;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	background: #ee4054;
	text-align: center;
	display: block;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

.cart-alert md-tooltip.cart-alert-tooltip, .cart-alert md-tooltip.cart-alert-tooltip div{
	font-size: 11px; 
	background-color: #d7d7d7;    
  }

  .cart-alert-tooltip .md-content.md-show{
	
	
	white-space: pre-wrap;
	overflow: hidden;
	line-height: 15px;
  }
  .swal2-overlay{
	z-index: 1000000;
  }
  .swal2-modal{
	z-index: 1000001;
  }


	.m-top-5{
		margin-top: 5px
	}
	.m-top-15{
		margin-top: 15px
	}

	.m-bottom-5{
		margin-bottom: 5px
	}
	.m-bottom-15{
		margin-bottom: 15px
	}

	.m-top-10{
		margin-top: 20px
	}
	.m-top-20{
		margin-top: 20px
	}

	.m-bottom-10{
		margin-bottom: 10px
	}
	.m-bottom-20{
		margin-bottom: 20px
	}

	.m-bottom-50{
		margin-bottom: 50px
	}


.no-data-msg{
	width: 100%;
	text-align: center;
	color: #666666;
	font-size: 28px;
	margin-bottom: 32px;
	font-family: 'Work Sans';
	font-weight: 500;
	border-top: 1px dashed;
	padding: 10px;
	border-bottom: 1px dashed;
}
  
.scale {
	background: #aa00ff;
	position: absolute;
	left: 0;
	right: 0;
	width: 0%;
	height: 10px;
	transition-duration: 2s;
	border-radius: 50px;
}

.signuplogin li:hover .dropdown-menu{
	display: block;
}

.signuplogin li.hideOp .dropdown-menu{
	display: none !important;
}

.notEligible button.checkoutproductbutton {
	background-color: #CDCDCD;
	cursor: not-allowed;
}

button.checkoutproductbutton.in-active{
	background-color: #CDCDCD;
	cursor: not-allowed;
}

.page-not-found{
	font-weight: 100;
	font-family: 'Lato';
	background: #fff;
	padding: 10%;
	text-align: center;
	font-size: 4em;
	margin-bottom: 10px;
}

.order-fetching {	
	background-color: rgba(235, 238, 240, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	background-image: url('../img/loading.gif');
	background-repeat: no-repeat;
	background-position: center;
}

#prevOrdersFetchLoading,#prevOrderFetchLoading{
	background-color: rgba(235, 238, 240, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	background-image: url('../img/loading.gif');
	background-repeat: no-repeat;
	background-position: center;
}

#prevOrderFetchLoading{
	min-height: 
}

button.alcohalfooter_shopordershopbtn:disabled {
background-color: rgba(170, 0, 255, 0.5);
}

/* SIDE BAR CSS */
.dropdown-menu{
	-webkit-animation-duration: 1s; 
  animation-duration: 1s; 
  -webkit-animation-fill-mode: both; 
  animation-fill-mode: both; 
}
.animate {
	display: block;
	-webkit-animation-name: fadeIn; 
	animation-name: fadeIn;     
}
@-webkit-keyframes fadeIn { 
  0% {opacity: 0;} 
  100% {opacity: 1;} 
} 
@keyframes fadeIn { 
  0% {opacity: 0;} 
  100% {opacity: 1;}
}
/* SIDE BAR CSS */




.product_addcart[ng-disabled='processing'] i.fa {
	font-size: 24px;
	float: left;
}

button { outline: none; }

.gift-products{

	margin-top: 15px;	

	padding: 15px 20px;

	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out;
}

.gift-products .input-group.bootstrap-touchspin{
	width: 80px;
	margin: auto;
}
.addcardform{
	border: 2px solid #CCC;
	border-radius: 10px;	
	padding: 10px;
	float: left;
	width: 100%;
	margin-top: 10px;
	position: relative;	
}

.cardicons{
	width: 100%;
	height: 50px !important;
	margin-top: 0px !important;
	background-image: url('../images/ccard.png');
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
}

.cardicons.visa{
	background-image: url('../images/visa.png');	
	
}
.cardicons.mastercard{
	background-image: url('../images/master.png');		
}

.addicon{
	background: #aa00ff;
	position: absolute;
	right: 0px;
	bottom: 2px;
	padding: 5px 4px;
	line-height: 0.5;
	color: #fff;
	font-weight: bold;
	border-radius: 100%;
}

.availablecards{
	margin-top: 10px;
}

.paymentselector{
	margin-bottom: 0px !important; 
}
.hideselector .md-container{
	display: none;
}

.paymentselector .md-label{
	width: 100% !important;
}

.hideselector .md-label{
	margin-left: 0px;
}

.loader{
	position: fixed;
	z-index: 10000;
	width: 100%;
	height: 100%;
	background: #FFF;
	display: none;
	margin: 0 auto;
}

body.loadingpayment{
	position: fixed;	
}

body.loadingpayment .loader{
	display: flex;
	justify-content: center; /* align horizontal */
	align-items: center; /* align vertical */
	background-image: url('../img/poslogo.png');
	background-position: center 40%;
	background-repeat: no-repeat;
} 
.gift-products{

	margin-top: 15px;	

	padding: 15px 20px;

	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out;
}

.gift-products .input-group.bootstrap-touchspin{
	width: 80px;
	margin: auto;
}

.gift-pack-products li{
	width: 60px;
	height: 60px;
	border: 1px solid #ccc;
	padding: 10px;
	text-align: center;
	margin: 10px 45px 0 5px;
	position: relative;
}

.credit_cash_pic {
	padding: 50px 10px;
	background: #A0F;
	color: #fff;
	font-size: 2.5em;
	text-align: center;
}


md-dialog#dont-miss-popup{
	max-width: 730px;
	width: 100%;
}

md-dialog#exchange-loyalty-popup{
	height: 300px;
}

md-dialog#exchange-loyalty-popup .-popup-blocks{
	padding: 50px 10px;
	background: #A0F;
	color: #fff;
	font-size: 2.5em;
	text-align: center;
	height: 163px;
}

#exchange-loyalty-popup md-dialog-content .fa {
	font-size: 33px;
	padding-top: 64px;
}

.prod_addtocart.disabled .cartbtn {
	background: #cdcdcd !important;
	font-size: 12px;
	font-weight: 500;
	color: #8f8f8f;
	height: 32px;
	text-align: center;
	text-transform: uppercase;
	border: 1px solid #ccc;
}
.prod_addtocart.disabled .cartbtn .addcart{
	cursor: not-allowed;
	 background: #cdcdcd !important;
}


.prod-in-slider{
	padding: 0 10px;
}

.prod-in-slider.dontmiss{
	padding: 0;
}

.prod-in-slider .owl-item > div {
	margin: 0px !important;
}

.prod-in-slider .owl-item .proHoverEffect {
	padding: 10px 5px;
	display: inline-block;
	margin: 10px 5px;
}

#dialogContent_dont-miss-popup {
	min-height: 400px;
}

md-checkbox.salecheck{
	opacity: 0;
	margin: 5px 0px;
}

md-checkbox.salecheck .md-icon, md-checkbox.salelist .md-icon{   
  transform: scale(0.8);  
}

md-checkbox.salecheck .md-label, md-checkbox.salelist .md-label{   
  font-size: 11px;  
  margin-left: 21px;
  color: #333;
}

.proHoverEffect:hover md-checkbox.salecheck,.comboproductitems:hover md-checkbox.salecheck{
	opacity: 1;
}


.saletags-list{
	position: absolute;
	bottom: 0px;
	margin: 0px;

	font-size: 0.8em;
	text-transform: uppercase;
	font-weight: 500;
}

.saletags-list li{
	background: #b119ff;
	padding: 1px 6px;
	color: #fff;
	border-radius: 2px;
	margin: 0;
}

.suggestionlist .proHoverEffect{	
	padding: 10px;
}
.pointer {
	cursor: pointer !important;
}

.newcardicons{
	/*width: 64px;
	height: 38px !important;*/
	width: 53px;
	height: 36px !important;
	margin-top: 0px !important;
	background-image: url('../images/credit_64px.jpg');
	background-repeat: no-repeat;
	background-position: 0px 40px;
	position: relative;
	float: left;
	margin-left: 10px;
}

.newcardicons.maestro{
	background-position: -15px -450px;
}
.newcardicons.dinersclub{
	background-position: -537px -192px;
}
.newcardicons.laser{
	background-position: -537px -364px;
}
.newcardicons.jcb{
	background-position: -450px -364px;
}
.newcardicons.unionpay{
	background-position: -189px -192px;
}
.newcardicons.discover{
	background-position: -15px -277px;
}
.newcardicons.mastercard{
	background-position: -102px -450px;
}
.newcardicons.amex{
	background-position: -276px -20px;
}
.newcardicons.visa{
	background-position: -102px -622px;
}

.salediscount{
	float: left;
	position: relative;
	text-transform: uppercase;
	/*font-weight: normal;*/
	color: #fff;
	font-size: 14px;    
	padding: 5px 5px 5px 52px;
	/*background: #aa00ff;*/
	height: auto;
	z-index: 2;
	line-height: normal;
	border-radius: 0px 2px 2px 0px;     
	width: 100%;
	text-align: left;
}

.sale-tag-txt.-cart{
	color: #8e02d4;
	font-size: 0.9em;
	font-weight: 500;
	display: inline-block;
}

/*.salediscount:before{
	content: "";
	position: absolute;
	left: -13px;
	top: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-right: 13px solid #aa00ff;
}*/


.salediscount:after{
	content: "";
	position: absolute;
	left: 10%;
	top: 48%;
	padding: 3px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background: #fff;
}

.nametag{
	/*float: left;*/
	width: auto;
	position: relative;
	text-transform: uppercase;    
	color: #564111;
	padding:5px 5px 5px 52px;
	background: #ffc412;    
	z-index: 2;    
	border-radius: 0 2px 2px 0;
	width: 100%;
	text-align: left;
	/*margin-left: 13px;*/     
}

/*.nametag:before{
	content: "";
	position: absolute;
	left: -13px;
	top: 45%;
	border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
	border-right: 13px solid #ffc412;
}*/

.nametag:after{
	content: "";
	position: absolute;
	left: 10%;
	top: 48%;
	padding: 3px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background: #fff;
}

.dontmissbut{
	color: #564111;
	margin-left: 10px;
}

.availablecards table{
	margin-top: 20px;
}

.sale-pro-list li{
	width: 60px;
	height: 60px;
	/*border: 1px solid #ccc;*/
	margin: 0 4px;
	padding: 5px;
}

.sale-pro-list li.sale-action-add-icon table {
	height: 100%;
	vertical-align: middle;
	font-size: 1.5em;
}

.sale-pro-list li.sale-action-add-icon table td{
	padding: 0 !important;
}

.sale-pro-list li.sale-action-add-icon {
	display: inline-block;
	vertical-align: top;
	width: auto;
}

.saletag, .saletag:hover{	
	color: #fff;
	padding: 1px;
	background: #aa00ff !important;
	border-radius: 2px;    
}

.saletag:before{
	content: "";
	position: absolute;
	left: 14px;
	top: 92;
	border-top: 9px solid transparent;
	border-bottom: 8px solid transparent;
	border-right: 7px solid #aa00ff;
}
.strike-txt,.strike-txt-only{
	text-decoration: line-through;
}

.cart-table .strike-txt{
	padding: 0 10px;
	font-weight: bold;
}

.cart-pro-count{
	border: 1px solid #cccccc;
	border-radius: 3px;
	height: 38px;
	width: 45px;
	display: inline-block;
	text-align: center;
	font-size: 18px !important;
	padding: 5px 10px;
	color: #000000;
	font-weight:500;
}

 .alcohalfooter_repeatorderbtn-rep {
	background: #aa00ff;
	border: medium none;
	border-radius: 5px;
	color: #fff;
	float: left;
	font-size: 11px;
	font-weight: 500;
	margin: 0;
	padding: 5px 20px;
	text-transform: uppercase;
}

.alcohalfooter_repeatorderbtn-add{
	background: #cdcdcd;
	border: medium none;
	border-radius: 5px;
	font-size: 11px;
	font-weight: 500;
	margin: 0;
	text-transform: uppercase;
	color: #8f8f8f;
	padding: 5px 15px;
}

.alcohalfooter_repeatorderbtn-add.active{
	background: #aa00ff;
	color: #fff;
}


.cartNonChilledActive .prod_chilled{
	cursor: not-allowed !important;
}

.prod_addtocart .addmore-count{
	position: relative;
	/*transition-duration: 1s;*/
}

.qty-tag-hang{
	position: absolute;
	top: 39%;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	color: #000;
	font-size: 12px;
	padding: 2px 0 0 0;
	right: -30px;
	white-space: nowrap;
}

.again21 a.search_icon{
	display: none;
}

.step-ratio[disabled] {
	cursor: not-allowed;
}

.step-ratio[disabled] .step-individual {
	cursor: not-allowed;

}
.closePopup{
	margin: 0;
	position: absolute;
	right: 8px;
	top: 5px;
	font-size: 20px;
}

.orderhistry2price .strike-txt-only {
	font-size: 0.9em;
	font-weight: 300;
	color: #666;
}

md-virtual-repeat-container {
  height: 50vh;
  min-height: 50vh;
  max-height: 50vh;
}

.md-autocomplete-suggestions-container .md-autocomplete-suggestions li {
  line-height: inherit;
  height: auto;
}

.procatname{
	color: #666666;
}

.nomargin{
	margin:0px;
}

.nopad{
	padding: 0px;
}

.md-autocomplete-suggestions-container.md-default-theme li:hover, 
.md-autocomplete-suggestions-container li:hover, 
.md-autocomplete-suggestions-container.md-default-theme li.selected, 
.md-autocomplete-suggestions-container li.selected{
	background: none;
}

button.detailwishbut{
	padding: 6px;
	line-height: 0;
}

.detailwish .add-wishlist-icondiv{
	display: block;
}

.detailwish .wishlist{
	left: 15px;
	min-width: auto;
}

.swal2-modal.swal-wide.show-swal2 {
	width: 50% !important;
	margin-right: auto !important;
	left: 25%;
	margin-left: auto !important;
}

.ldmsg{
	z-index: 80000;position: fixed;bottom: 30%;width: 100%;	
}

.checkouterwantfirst span small {
	font-size: 100%;
}

.checkouterexprienceouter.disabled:after {
	background: rgba(34, 35, 40, 0.32);
	content: "";
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	z-index: 1;
}

#express-disable-note{
	display: none;
}
.checkouterexprienceouter.disabled #express-disable-note{
	display: block;
}
.checkouterexprienceouter.disabled #express-disable-note {
	display: block;
	background: #fff;
	z-index: 2;
	position: relative;
	text-align: center;
	font-weight: bold;
	text-decoration: underline;
}


.bootstrap-touchspin.vertical span.addmore-count{

}

.table-responsive{
	overflow-x:visible;
}

#credits-input{
	width: 38px;
	padding: 2px 5px;
	border: 1px solid #ccc;
	border-radius: 2px;
	box-shadow: 0px 0px 0px 1px;
}

.negative-field:before{
	content: "- ";
	font-size: 1.3em;
}

.loading-message {
  display: inline-block;
  min-width: 125px;
  margin-left: -60px;
  padding: 10px;
  margin: 0 auto;
  color: #000 !important;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.loading-message.loading-message-boxed {
  border: 1px solid #ddd;
  background-color: #eee;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
.loading-message > span {
  line-height: 20px;
  vertical-align: middle;
}

.page-loading {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 125px;
  margin-left: -60px;
  margin-top: -30px;
  padding: 7px;
  text-align: center;
  color: #333;
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: #eee;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
.page-loading > span {
  line-height: 20px;
  vertical-align: middle;
}

.page-spinner-bar {
  position: fixed;
  z-index: 10051;
  width: 100px;
  top: 40%;
  left: 50%;
  margin-left: -55px;
  text-align: center;
}
.page-spinner-bar > div {
  margin: 0 5px;
  width: 18px;
  height: 18px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.page-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.page-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.block-spinner-bar {
  display: inline-block;
  width: 80px;
  text-align: center;
}
.block-spinner-bar > div {
  margin: 0 2px;
  width: 15px;
  height: 15px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.block-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.block-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.page-spinner-bar > div, .block-spinner-bar > div {
	background: #aa00ff;
}

@-webkit-keyframes bounceDelay {
  0%, 80%, 100% {
	-webkit-transform: scale(0);
  }
  40% {
	-webkit-transform: scale(1);
  }
}	
@keyframes bounceDelay {
  0%, 80%, 100% {
	transform: scale(0);
	-webkit-transform: scale(0);
  }
  40% {
	transform: scale(1);
	-webkit-transform: scale(1);
  }
}


td.childtd {
	padding: 10px 0px !important;
}

.childtd table tr:first-child td{
	border-top: 0 none;
}

.cart-table td{
	vertical-align: middle;
}

.cardpreview{
	padding: 0px 4px 0px 4px;
	background: none !important;
	border:0 none;
}

#review-table .prod_chilled_icon, 
#review-table .prod_chilled{
	cursor: default;
	margin: 0px;
}
.checkboxtotaldiv-text-font-size{
	position: static;
}

.split-txt{
	word-break: break-all;
}

#timeslots.loadingSlots:after {
	content: "";
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	left: 0;
	cursor: no-drop;
}

.ageverificationpopup{
	background: #FFC412;
}

.ageverify{
	background: #aa00ff !important; 
	color: white;
}

button.ageverify:disabled{
	background: transparent !important;
	color: black;
}

.maintab{
	background: inherit;
}

.maintab .md-tab{
	text-transform: none;
}
.product-bread-crumb{
	
}

md-tooltip.ad-tooltip .md-content {
	background: #d7d7d7;
}
.cur-help{
	cursor: help;
}

md-dialog md-dialog-actions{
	padding-right: 0 !important;
	padding-left: 1px !important;
}

md-toast.notify.bottom{
	position: fixed;
	bottom: 0;
}
.dependencypopuptext-title i.fa{
	position: absolute;
	left: 0;
	font-size: 52px;
}

span.help-block a {
	text-decoration: underline;
	color: #454545;
}

.pro-notavail-footer {
	width: 100%;
	background: #ccc;
	font-size: 1.3em;
	
}
.single-slot.inactive .fa-info{display: none;}
.single-slot .fa-info{
	color: red;
	width: 14px;
	border-radius: 13px;
	background: #fff;
	padding-top: 3px;
	height: 14px;
	font-size: 8px;
}

.recentorder  md-checkbox.full{
	width: 100%;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 0px;
}

.recentorder  md-checkbox.full .md-icon:after{
	left : 4.76667px;
}
.recentorder md-checkbox.full .md-container{
	margin-left: 21px;
}

.productslide_discountprice.strike-txt-only {
	color: #737e83;
	font-size: 13px;
}

.grey-txt{
	color: #737e83 !important;
}

.pro-pricing{
	font-size: 13px;
	display: inline-block;
}
.pro-pricing .-dis-price{
	font-weight: 500;
	font-size: 2em;
}

.pro-pricing .-dis-price + .-unit-price{
	text-decoration: line-through;
}

.pro-pricing .-dis-price:not + .-unit-price{
	font-weight: 500;
	font-size: 2em;
}

a.read-more, a.read-less {
	cursor: pointer;
	color: #8d00d4;
}

span.more-text {
	display: none;
} 
span.more-text.show {
		display: inline !important
}

.na-pro-for-gift{
	padding: 40px 20px 15px 20px;
	color: #a0f;
	font-size: 25px;
}

.product-req-add{
	font-size: 50px;
	border: 2px solid #a0f;
	width: 100px;
	border-radius: 100%;
	text-align: center;
	padding: 24px 10px;
	color: #a0f;
	
	cursor: pointer;
}
.product-req-add:hover{
	box-shadow: 0px 2px 5px 2px #ccc;
}

body {
	top: 0px !important;
}

/*********************
* Social Buttons
*********************/

.btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
  border: 1px solid #d54331;
}

.btn-google-plus:hover,
.btn-google-plus:focus {
  color: #fff;
  background-color: #cb3927;
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border: 1px solid #335190;
}

.btn-facebook:hover,
.btn-facebook:focus {
  color: #fff;
  background-color: #294786;
}

.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border: 1px solid #0073ae;
}

.btn-linkedin:hover,
.btn-linkedin:focus {
  color: #fff;
  background-color: #0069a4;
}

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  border: 1px solid #009fdb;
}

.btn-twitter:hover,
.btn-twitter:focus {
  color: #fff;
  background-color: #0090c7;
}

.btn-foursquare {
  color: #fff;
  background-color: #f94877;
  border: 1px solid #f8215a;
}

.btn-foursquare:hover,
.btn-foursquare:focus {
  color: #fff;
  background-color: #f71752;
}

.btn-github {
  color: #fff;
  background-color: #444;
  border: 1px solid #3b3b3b;
}

.btn-github:hover,
.btn-github:focus {
  color: #fff;
  background-color: #303030;
}

.btn-instagram {
  color: #fff;
  background-color: #3f729b;
}

.btn-instagram:hover,
.btn-instagram:focus {
  color: #fff;
  background-color: #305777;
}

.btn-yahoo {
  color: #fff;
  background-color: #720e9e;
}

.btn-yahoo:hover,
.btn-yahoo:focus {
  color: #fff;
  background-color: #600e85;
}

.btn-live {
  color: #fff;
  background-color: #2672ec;
}

.btn-live:hover,
.btn-live:focus {
  color: #fff;
  background-color: #125acd;
}

.btn-twitch {
  color: #fff;
  background-color: #6441a5;
}

.btn-twitch:hover,
.btn-twitch:focus {
  color: #fff;
  background-color: #7550ba;
}

.btn-bitbucket {
  color: #fff;
  background-color: #10375e;
}

.btn-bitbucket:hover,
.btn-bitbucket:focus {
  color: #fff;
  background-color: #0d2c4a;
}

.btn-spotify {
  color: #fff;
  background-color: #2ebd59;
}

.btn-spotify:hover,
.btn-spotify:focus {
  color: #fff;
  background-color: #29a34e;
}

.signup-or-separator {
  position: relative;
  height: 34px;
  text-align: center;
  background: none;
}

.signup-or-separator hr {
  width: 100%;
  margin: -16px auto 10px auto;
  border-top: 1px solid #dce0e0;
}

.signup-or-separator .text {
  display: inline-block;
  padding: 8px;
  margin: 0;
  background-color: #fff;
}

.loginotheroption{
	margin-top: 15px;
}

.btn-social {
	position: relative;
	margin-bottom: 10px;
	padding-left: 44px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.btn-social>:first-child {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 32px;
	line-height: 32px;
	font-size: 1.2em;
	text-align: center;
	border-right: 1px solid rgba(0,0,0,0.2);
}

.add-default-check{
	color: #222;
	font-weight: normal;
}

#address-default-tag{
	position: absolute;
	right: 10px;
	bottom: 20px;
	color: #ada6a6;
	font-style: italic;
}

input[type='text'].transparent{
	border: 0px;
	width: 100%;
	background: transparent;
	padding: 0 2px;
}

.mobileinput{
	width: 60px;
}

md-checkbox.md-focused .md-container:before{
	background-color:transparent !important;
}

/*.col-md-3.party-detail-btm-width {
	padding: 0 0 0 2%;
}*/

.package-group-products>span:after {
	content: ",";
	font-weight: bold;
}
.package-group-products>span:last-child:after {
	content: "";
}

.package-pro-order-show{
	cursor: pointer;
	float: left;
    height: 57px;
    text-align: center;
    vertical-align: middle;
    margin-right: 15px;
}

.package-pro-order-show .-detail{
	
}

.package-pro-order-show .-img{
	border: 1px solid #dddddd;
    width: 60px;
    position: relative;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    padding: 3px;
}

.social-nolabel{
	height: 32px;
}

.social-nolabel>:first-child{
	border-right: 0 none;
	width: 100%;
}

.tdown {
	transform: rotate(180deg);	
}

.product-list-thumb {
    position: relative;
    padding-left: 80px;
}

.product-list-thumb img {
    max-width: 70px;
    max-height: 70px;
    position: absolute;
    left: 0;
}

.product-wishlist {
    padding: 10px 0;
    border-top: 1px solid #ddd;
}

.addshadow{	
    box-shadow: 0 1px 2px #bbb;
    -webkit-box-shadow: 0 1px 2px #bbb;
    -moz-box-shadow: 0 1px 2px #bbb;
    border-radius: 2px;
    -webkit-border-radius: 2px;
}

.navbar-shrink #icon {
	margin-top: -9px;
	transition: inherit;
}

.popupcart{
	position: relative;
	left: 7px;
}

.popupcart:before{
	left: -6px;	
}

.currentorderpopupulleft img{
	float: left;
	width: 50px;
	margin-right:8px;
}

.itemhelperdiv{
	width: 50px;
	margin-right: 8px;
	float: left;
	text-align: center;	
}

.giftitemlist{
	margin-left: 53px;
	width: 78%;
	float: left;
}

.giftitemlist img {
	width: 30px;
	border: 1px solid #ccc;
	margin: 5px;
}

button.processing{
	background: rgb(128, 128, 128) !important;
}

.md-toolbar-tools{
	max-height: 50px;
}

md-tooltip .md-content{
	white-space: normal;
	padding: 5px;
	height: auto;
	line-height: normal;
	font-size: 12px;
	color: #222122;
	background: #ccc;
}

.prod_desc p {
    margin: 0;    
}

/*.prod_desc.tgcontain {
    display: block;
    min-height: 20px;
}*/

.announcement-container{
	/*min-height: 50px;*/
}

/*.announcement-container span{
	padding: 17px 0;
}*/

#announcement-bar-close-btn{
	position: absolute;
    right: 12px;
    cursor: pointer;    
}

#announcement-bar-close-btn:hover{
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.24)
}

.recentorder{
	margin-bottom: 25px;
}

#wrapper{
	transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-web-transition: all 0.3s ease 0s;
}


.wishlistpopuppinkbox .additempopuppinkboxright a,.wishlistpopuppinkbox .additempopuppinkboxright a:focus {
    margin: 0;
    padding: 0;
    color: inherit;
    vertical-align: top;
    text-decoration: underline;
}

.checkouter-td-right-text div {
    white-space: normal;
}

.img-responsive-h-package{
	max-height: 100%;
	margin: 0 auto;
}

.ap-required-label:after{
	content: ' *';
    color: red;
}

.cmspage-formcrv textarea.form-control{
	height: 100px;
}

.samplerequest{
	border-color: #444;	
	background: #444;
	width: 50%;
}

.samplerequest .addcart{
	background: #444;
}

.btnadjust .cartbtn{
	width: 50%;
	float: left;
}

.reqsample p{
	text-align: left;
}

.owl-prev, .owl-next {
    position: absolute;
    top: 44%;
    margin-top: -50px;
    width: 50px;
    text-align: center;
    background-color: transparent !important;
    border:none;
    font-size: 29px !important; 
}

.owl-prev{
	left: 0px;
}

.carousel .owl-prev, .owl-next {
    background-color: #000;
}

.owl-next {
    right: 0px;
}

md-toast.notify .md-toast-content:before{
	content: none;
	min-height: none !important;
}

/* Makers CSS */

.content_1 h2 {
	margin-top: 50px;
	margin-left: 150px;
  }
  .content_1 p {
	margin-left: 256px;
  }

  .imgfewfewAsset1 {
	width: 45px;
  }
  .navbar-img {
	margin-top: 50px;
  }
  .container-img {
	width: 500%;
	margin-left: 250px;
  }

  #makers-menu{
	background: #f5eace;
	padding: 10px;
	/* height: 688px; */
  }
  #makers-menu ul {
	list-style: none;
	/* margin: 0; */
	margin-top: 30px;
	padding: 0;
  }
  #makers-menu li {
    text-align: center;
    margin: 35;
	padding: 0 70px;
}
  #makers-menu li a {
    display: inline-block;
    font-family: 'InterstateMono', sans-serif;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    color: black;
}
  #makers-menu li a:hover {
	color: red;
  }
  #get-started-banner {
	background-image: url('../assets/makers/Group 13.png');
	max-height: 871px;
	/* width: 1894px; */
	background-repeat: no-repeat;
	background-size: cover;
  }
  #alcohol-logo{
	width: 118px;
    height: 53px;
    height: 53px;
    position: absolute;
    right: 27px;
    top: 26px;
  }
  #makers-logo{
	width: 112px;
    height: 46px;
    position: absolute;
    right: 179px;
    top: 37px;
  }
  #bottle-image{
	position: absolute;
	width: 100%;
    max-width: 646px;
    height: 976px;
    transform: rotate(1deg);
    top: 35px;
    left: 52px;
	padding: 40px 0px;
  }
  #banner-text{
	color: #f5eace;
	
  }
  #banner_one_text h2{
	width: 100%;
	max-width: 614px;
	color: #f5eace;
	margin-left: 650px;
  } 

  #banner_one_text p{
	width: 100px;
	max-width: 614px;
	height:125px;
	margin-left: 650px;
	font-size: 21px;
	font-weight: normal;
	/* line-height: 25px; */
	font-style: normal;
	text-align: center;
	color: #fbf2ba;
  }
  #banner-text p{
	width: 100%;
	max-width: 735px;
	height: 192px;
	line-height: 91px;
	text-align: center;
	margin-top: 100px;
  }
  #arrow-logo{
	position: absolute;
    width: 818px;
    height: 744px;
    left: 43px;
    top: -87px;
  }
  #banner-button{
	text-align: center;
	width: 200px;
  }
  #direction-img{
    width: 99px;
    height: 99px;
    right: 32px;
    position: absolute;
    bottom: 47px;
  }

  #bottle-arrow{
	position: relative;
    width: 173px;
    height: 78px;
    margin-top: 732px;
    margin-left: 320px;
    bottom: 52px;		
  }

  /* #get-started-banner p{
	width: 100%;
	max-width: 483px;
	height: 103px;
	margin-top: 638px;
	margin-left: 348px;
	font-size: 46px;
	font-weight: normal;
	font-style: italic;
	color: #f4e8ce;
	transform: rotate(6deg);
  } */
	#banner-second{
		width: 100%;
    	height: 798px;
    	opacity: 1.00;
    	max-width: 1904px;
    	margin-top: -10px;
	}
	#banner2 h2 {
    	width: 100%;
    	margin-top: -683px;
    	max-width: 1231px;
    	height: 107px;
    	font-family: "Makers Serif";
    	font-size: 47px;
    	font-weight: normal;
    	font-style: normal;
    	text-align: center;
    	color: #000000;
    	margin-left: 332px;
	}
	#banner2 p{
		width: 100%;
    	max-width: 931px;
    	height: 97px;
    	margin-top: 25px;
    	margin-left: 509px;
    	font-family: "Interstate";
    	font-size: 27px;
    	font-weight: normal;
    	line-height: 33px;
    	font-style: normal;
    	text-align: center;
    	color: #000000;
	}
	#banner2 button{
		width: 100%;
    	max-width: 262px;
    	height: 60px;
    	background: #bc0c20;
    	color: #ffffff;
    	font-family: "Interstate";
    	font-size: 21px;
    	font-weight: bold;
    	font-style: normal;
    	text-align: center;
    	margin-top: 302px;
    	margin-left: 782px;
    	text-align: center;
	}
	#banner-label{
		position: absolute;
    	width: 100%;
    	max-width: 644px;
    	height: 337px;
    	text-align: center;
    	margin-top: -42px;
    	margin-left: 601px;
	}
	#carousel-banner{
		width: 100%;
		margin-top: 83px;
		max-width: 1893px;
	}
	#carousel_label{
	  width: 100%;
	  max-width: 1898px;
	  position: absolute;
	  background-color: #bc0c20;
	}
	#banner-message{
		width: 100%;
		max-width: 1671px;
		height: 528px;
		margin-left: 103px;
		margin-top: 49px;
		top: 82px;
		left: 112px;
		border-color: #be1033;
		border-width: 2px;
		border-style: solid;
		border-radius: 38px;
		background: #fcedc5;
		opacity: 1.00;
	}
	#start-slider{
	  position: absolute;
	  width: 449px;
	  height: 449px;
	  border-radius: 37px;
	  margin-top: 150px;
	  margin-right: 50px;
	}
	#label_name_banner {
		background: #bc0c20;
	  /* background-image: url('../assets/makers/Rectangle\ 43.png'); */
	  	background: #bc0c20;
    	width: 100%;
    	max-width: 1898vh;
    	height: 680px;;
	}
	#label_name_banner h2{
		width: 100%;
		margin-left: 317px;
		margin-top: -424px;
		font-family: "Makers Serif";
		font-size: 47px;
		font-weight: normal;
		font-style: normal;
		color: #000000;
	}
	#label_name_banner h5 {
	  width: 100%;
	  max-width: 527px;
	  margin-left: 301px;
	  height: 133px;
	  font-family: "Interstate";
	  font-size: 25px;
	  font-weight: normal;
	  line-height: 30px;
	  font-style: normal;
	  text-align: left;
	  color: #000000;
	}
	#label_name_banner p{
	  width: 618px;
	  height: 29px;
	  opacity: 0.44;
	  margin-left: 298px;
	  font-family: "Interstate";
	  font-size: 25px;
	  font-weight: normal;
	  line-height: 30px;
	  font-style: normal;
	  text-align: left;
	  color: #000000;

	}
	#label_name_banner button{
	  width: 100%;
	  max-width: 262px;
	  height: 60px;
	  margin-left: 636px;
	  background: #bc0c20;
	  font-family: "Interstate";
	  font-size: 21px;
	  font-weight: bold;
	  font-style: normal;
	  text-align: center;
	  color: #ffffff;
	}
	#carousel1{
	  position: relative;
      width: 100%;
      max-width: 449px;
      height: 449px;
      border-radius: 37px;
      margin-top: -757px;
      margin-left: 1123px;
	}
	#carousel2{
	  position: absolute;
	  width: 100%;
	  max-width: 1671px;
	  height: 528px;
	  top: 3129px;
	  left: 112px ;
	  border-color: #be1033;
	  border-width: 2px;
	  border-style: solid;
	  border-radius: 38px;
	  background: #ffffff
	}
	#carousel3{
	  position: absolute;
	  width: 100%;
	  max-width: 1671px;
	  height: 528px;
	  left: 112px;
	  top: 3726px;
	  border-color: #be1033;
	  border-width: 2px;
	  border-style: solid;
	  border-radius: 38px;
	  background: #f5f5f5
	}
	
	#testimonial_banner{
	  width: 100%;
	  max-width: 1909px;
	  height: 961px;
	  margin-left: -4px;
	  border-color: #000000;
	  border-width: 1px;
		/* background: #231f20   */
	}
	
	#testimonial_one{
	  position: relative;
	  width: 100%;
	  max-width: 427px;
	  height: 513px;
	  margin-left: 265px;
	  border-color: #efe0c1;
	  border-width: 2px;
	  border-style: solid;
	  border-radius: 35px;
	  margin-top: -875px;
	}
	#testimonial p{
	  position: relative;
	  width: 100%;
	  max-width: 321px;
	  height: 298px;
	  margin-left: 310px;
	  margin-top: -590px;
	  border-color: #000000;
	  border-width: 1px;
	  font-family: "Interstate";
	  font-size: 24px;
	  font-weight: normal;
	  line-height: 30px;
	  font-style: normal;
	  text-align: left;
	  color: #efe0c1;
	}
	#testimonial_second{
	  position: relative;
	  width: 100%;
	  max-width: 427px;
	  height: 513px;
	  margin-left: 730px;
	  margin-top: -875px;
	  border-color: #efe0c1;
	  border-width: 2px;
	  border-style: solid;
	  border-radius: 26px;
	  background: #efe0c1
	}
	#testimonial2 p{
	  position: relative;
	  width: 100%;
	  max-width: 335px;
	  height: 391px;
	  margin-left: 767px;
	  margin-top: -645px;
	  font-family: "Interstate";
	  font-size: 27px;
	  font-weight: normal;
	  line-height: 30px;
	  font-style: normal;
	  text-align: left;
	  color: #5e5e5e;
	}
	#testimonial_third{
	  position: relative;
	  width: 100%;
	  max-width: 427px;
	  margin-left: 1194px;
	  margin-top: -875px;
	  height: 513px;
	  border-color: #efe0c1;
	  border-width: 2px;
	  border-style: solid;
	  border-radius: 35px;
	}
	#testimonial3 p{
	  position: relative;
	  width: 100%;
	  max-width: 314px;
	  margin-left: 1246px;
	  margin-top: -625px ;
	  height: 328px;
	  font-family: "Interstate";
	  font-size: 24px;
	  font-weight: normal;
	  line-height: 30px;
	  font-style: normal;
	  text-align: left;
	  color: #efe0c1;
	}
	#footer_banner img{
	  width: 100%;
	}
	#footer_banner ul{
	  list-style: none;
	}
	#footer_banner li{
	  text-align: center;
	}
	#footer_banner li a{
	  margin-top: 200px;
	  width: 100%;
	  color: #000000;

	}
	#facebook_icon{
	  position: relative;
	  max-width: 32px;
	  height: 32px;
	  float: right;
	  /* margin-top: -357px; */
	}
	#instagram_icon{
	  position: relative;
	  max-width: 32px;
	  height: 32px;
	  float: right;
	  /* margin-top: -357px; */
	}
	#twitter_icon{
	  position: relative;
	  max-width: 32px;
	  height: 26px;
	  float: right;
	  margin-top: -357px;
	  margin-right: 105px;
	}
	#pinterest_icon{
	  position: relative;
	  max-width: 35px;
	  height: 35px;
	  float: right;
	  margin-top: -360dpx;
	  margin-right: 153px;
	}
	#youtube_icon{
	  position: relative;
	  max-width: 38px;
	  height: 27px;
	  float: right;
	  margin-top: -357px;
	  margin-right: 52px;
	}

/* Makers CSS */