/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
@media  (max-width : 1023px) {
body {
	/*overflow:auto;*/
	overflow-y: scroll;
	overflow-x: hidden;

}

body.md-dialog-is-showing{
	overflow-x: hidden;
	overflow-y: hidden;
	top: 0px !important; 
}

/* Toggle Styles */

#wrapper {
	padding-left: 0;
	/*-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;*/
	
	-moz-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	-webkit-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
#wrapper.toggled {
	
	height:100%;
}
#sidebar-wrapper {
	z-index: 1000;
	position: fixed;
	left: 250px;
	width: 0;
	height: 100%;
	margin-left: -250px;
	overflow-y: auto;
	background: #000;
	/*-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;*/
	
	-moz-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	-webkit-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
#wrapper.toggled #sidebar-wrapper {
	width: 250px;
}
#page-content-wrapper {
	width: 100%;
	position: absolute;
}
#wrapper.toggled #page-content-wrapper {
	position: absolute;
	margin-right: -250px;
}
/* Sidebar Styles */

.sidebar-nav {
	position: absolute;
	top: 0;
	width: 250px;
	margin: 0;
	padding: 0;
	list-style: none;
}
.sidebar-nav li {
	text-indent: 20px;
	line-height: 40px;
}
.sidebar-nav li a {
	display: block;
	text-decoration: none;
	color: #999999;
}
.sidebar-nav li a:hover {
	text-decoration: none;
	color: #fff;
	background: rgba(255,255,255,0.2);
}
.sidebar-nav li a:active, .sidebar-nav li a:focus {
	text-decoration: none;
}
.sidebar-nav > .sidebar-brand {
	height: 65px;
	font-size: 18px;
	line-height: 60px;
}
.sidebar-nav > .sidebar-brand a {
	color: #999999;
}
.sidebar-nav > .sidebar-brand a:hover {
	color: #fff;
	background: none;
}
 @media(min-width:768px) {
/*#wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }*/
}
.side-menu-responsive, .laeftdivmain{
	display: block;
	width:100%;
	opacity: 1;
	position: fixed;
	background: #333;
	/*-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;*/
	
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	padding: 0;
	margin: 0;
	transform: translate(-100%);
	-webkit-transform: translate(-100%);
	-ms-transform: translate(-100%);
	
}
#wrapper.toggled .side-menu-responsive, #wrapper.toggled .laeftdivmain {
	 
	transform: translate(0px, 0px);
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	opacity: 1;
	/*-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;*/
	
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	z-index: 1052;
	
}
#bs-example-navbar-collapse-1 {
	display: block;
	border: 0 none;
}
.navbar-default .navbar-nav li a, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	color: #FFF;
}
.side-menu-responsive .navbar-header {
	display: none;
}
#wrapper.toggled .navbar.navbar-default.navbar-fixed-top {
	position: fixed;
	transform: translate(0%, 0);
	 -webkit-transform: translate(0%, 0);
	 z-index: 0
}
#wrapper.toggled .christmas-mainouter{
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	transition: all .3s ease-out;
	transform: translate(0%, 0);
	-webkit-transform: translate(0%, 0);
}
.christmas-mainouter{
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}
.menu-icon > .line {
	background-color: #614911;
	height: 4px;
	display: block;
	width: 30px;
	border-radius: 7px;
	z-index: 100000;
}
.menu-icon > .line + .line {
	margin-top: 5px;
}
.mobilemenu {
	display: block !important;
}
.navbar-nav {
	margin: 0px 10px;
}
.menu-icon {
	position: absolute;
	top: 40px;
	z-index: 9999;
	padding: 10px;
	top: 31px
}
.navbar-shrink .menu-icon {
	top: 15px
}
.modify .dropdown-menu li {
	margin-right: 0;
	width: 100%;
}
.innerpagemargintop .nav.navbar-nav .dropdown-menu {
	position: inherit;
	background: #5f5f5f none repeat scroll 0 0 !important;
	min-width: 100%;
	left: 0;
	float: left;
	color: #fff;
	padding:0;
}
.modify .dropdown-menu li a, .modify .dropdown-menu li a:hover, .modify .dropdown-menu li a:active, .modify .dropdown-menu li a:focus {
	color: #fff !important;
	background: none !important
}

.dm_title.ng-binding {
	display: none;
}

.dropdown-menu.ng-scope.animate {
    padding: 0px 0px !important;
	box-shadow:none;
}

/*.dropdown-menu.ng-scope.animate li a{
	padding-top:6px  !important;
		padding-bottom:6px  !important;
}*/

.dropdown.ng-scope.ng-isolate-scope.open  .dropdown-menu{
	 padding: 8px 0!important;
	 
}

.navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu > li > a{
	 padding:5px 15px 6px 15px;
}

ul.mobilesidebar li.active:not(.open) ul{
	display: block;
}
.username.dropdown-toggle.ng-binding {
    max-width: 100%;
}
.dropdown.mobilemenu.hide.hideOp .dropdown-menu.profil-dropdown{
	display:block !important
	
}
.modify .dropdown-menu{
	box-shadow:none
	
}
.profil-dropdown::before{
	display:none
}
.navbar-nav{
    margin: 0px 10px;
}

.laeftdivmain{
    left: 0;    
    top:0;
    padding: 0px 10px;
    /*width: auto;*/
    background: #ebeef0 ;
    z-index: 1052;
	
}
.modal {
	z-index: 99999;
}
.closemenu  {
    color: #ffffff;
    cursor: pointer;
    float: right;
    font-size: 51px;
    line-height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    z-index: 9999;
}
.laeftdivmain .closemenu{
	color: #69696a;

}
/*.mobilemenu  a{
	float: left;

}*/
}