/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-theme .owl-controls{
	margin-top: 0px;
	text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	color:#b0b0b0;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 0px;
	padding: 4px 8px;
	font-size: 15px;
	background: #e2e2e2;
	/*filter: Alpha(Opacity=50);
	opacity: 0.5;*/
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	/*filter: Alpha(Opacity=100);
	opacity: 1;*/
	text-decoration: none;	
	color: #656565;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 8px;
	height: 8px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #c1c1c1;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	background: #aa00ff;
	width: 8px;
	height: 8px;
	margin:5px 7px;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center
}

.owl-prev{
	border-radius:5px 0px 0 5px;
	border:1px solid #d7d7d7;
}

.owl-next{
	border-radius:0px 5px 5px 0px;
	border:1px solid #d7d7d7;
	border-left:none;
}