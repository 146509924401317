/*party-package-page*/
.partypackegescover {
	width: 100%;
	margin-bottom: 10px;
}
.ttile-divcover.cocktail-title-float.ng-scope {
	float: left;
}
.title-propertset {
	color: #656565;
	width: 100%;
	float: left;
	font-size: 24px;
}
.subtitl-propertset {
	font-size: 18px;
	color: #656565;
	width: 100%;
	float: left;
	font-weight: 500;
	padding-bottom: 6px;
	padding-top: 6px;
}
.btmcontn-set {
	float: left;
	width: 100%;
}
.package-desc {
	float: left;
	font-size: 13px;
	font-weight: 400;
	max-width: 79%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #656565;
}
.btmcontn-set a {
	color: #aa00ff;
	float: left;
	font-size: 15px;
	margin: -1px 0 0;
	padding: 0;
	text-align: right;
	text-decoration: none;
	width: 21%;
}
.customize-propertyset {
	color: #676767;
	font-size: 15px;
	font-weight: 500;
	margin: 0;
	padding-left: 20px;
	padding-top: 20px;
}
.ttile-divcover.ng-scope > span {
	color: #a9a8a9;
	display: inline-block;
	font-size: 13px;
	width: 65%;
	overflow: hidden;
	/*white-space: nowrap;*/
	text-overflow: ellipsis;
}
.custmize-contntset {
	margin-top: 0;
	color: #676767;
	width: 30%;
	float: left;
	font-size: 14px
}
.margincbtm-packg {
}
.party-alcholcover .byz::after, .party-alcholcover .byz::before {
	background-color: transparent;
	background-image: url("../images/edi-partyt-icon.png");
	background-position: center top;
	background-repeat: no-repeat;
	height: 20px;
	margin-top: -10px;
	width: 30px;
}
.party-alcholcover .byz::before {
	background-image: url(../images/open-rightmark.png);
	background-repeat: no-repeat;
	background-position: center;
	transform: none !important;
}
.bg-removepackage::after, .bg-removepackage::before {
	background-color: transparent !important;
}
.rgrhtdiv {
	width: 80% !important
}
.byz.ng-scope {
	margin-bottom: 10px;
	border-bottom: 1px solid #d8d8d8 !important;
}
.bg-removepackage {
	border-bottom: medium none !important;
	margin-bottom: 0 !important;
	margin-top: -1px;
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}
.xyz.ng-scope.ng-isolate-scope {
	background: #ffffff;
}
.ttile-divcover.ng-scope {
	width: 100%;
}
.item-btmtitle.ng-binding {
	margin-top: 0;
	text-align: center;
	width: 100%;
	height: 40px;
	color: #676767;
	font-size: 14px;
}
.thumbnailheightset {
	min-height: 230px;
	padding-bottom: 18px;
	padding-top: 0;
}
.item-btmtitle.ng-binding {
	height: 45px;
	overflow: hidden;
	margin-top: 0;
	text-align: center;
	width: 100%;
}
.checkoutmiddlevlauediv {
	margin: 15px auto 0
}
/*.thumbnailheightset:hover, .thumbnailheightset:active, .thumbnailheightset:focus,*/ 

.cart-table .checkoutmiddlevlauediv {
	margin: 0px auto 0
}
.selectedproductcart {
	border: 1px solid #8c00d3;
	box-shadow: 3px 3px 3px 3px #e5c8f6;
}
.amntrupee-cls {
	margin-top: 0;
	float: left;
	margin-top: 0;
	width: 100%;
}
.packtiti-rightset {
	margin-bottom: 0;
	color: #aa00ff;
	float: left;
	margin-bottom: 0;
	width: 100%;
}
.pardqantitytexthead {
	margin-top: 6px;
	font-weight: 500
}
.party-alcholcover {
	margin-bottom: 45px;
}
.giftcard-rightpanel {
	width: 100%;
	margin-bottom: 12px;
	float: left
}
.gift-breadcrumbscover {
	width: 100%;
	float: left;
	padding: 20px 15px;
}
.gift-breadcrumbscover .breadcrumb > li + li::before {
	color: #ccc;
	content: "> ";
	padding: 0 5px;
}
.gift-breadcrumbscover .breadcrumb {
	background: transparent none repeat scroll 0 0;
	border-radius: 0;
	padding: 0;
	margin-bottom: 0
}
.breadcrumb a {
	color: #7a7a7a;
	font-size: 13px
}
.breadcrumb > .active {
	color: #222122;
	font-size: 13px;
	font-weight: 500;
}
.giftcard-maincontentcover {
	width: 100%;
	float: left;
	padding-left: 20px;
	padding-top: 20px;
}
.boxshadow-maincontnt-giftcard {
	-webkit-box-shadow: 0 2px 3px -1px #babdbf;
	-moz-box-shadow: 0 2px 3px -1px #babdbf;
	box-shadow: 0 2px 3px -1px #babdbf;
	background: #ffffff none repeat scroll 0 0;
	border-radius: 5px;
	float: left;
	margin-bottom: 10px;
	width: 100%;
}
.left-giftcardcoverimg {
	float: left;
	height: 418px;
	width: 416px;
	display: table;
	background: #f2f2f2;
}
.giftcard-imgcover {
	display: table-cell;
	vertical-align: middle;
	text-align: center
}
.left-gftmaincontncover {
	width: 50%;
	float: left;
}
.right-gftmaincontncover {
	width: 50%;
	float: left;
	padding-right: 30px;
}
.giftcard-textright {
	width: 100%;
	float: left;
	color: #666666;
	font-size: 28px;
	font-weight: 500;
	padding-top: 12px;
}
.card-btmtextdiv {
	width: 100%;
	float: left;
	color: #666666;
	font-size: 13px;
	padding-bottom: 30px;
	padding-top: 30px;
}
.card-detailtext {
	width: 100%;
	float: left;
	color: #666666;
	font-size: 15px;
	padding-bottom: 20px;
}
.amnt-inputgftcover {
	width: 100%;
	float: left;
	padding-bottom: 20px
}
.gift-ampunttxtcove {
	width: 100%;
	float: left;
	color: #666666;
	font-size: 12px;
	margin-bottom: 5px
}
.amnt-inputgftcover input {
	width: 51px;
	height: 33px;
	float: left;
	margin-right: 8px;
	font-size: 15px;
	color: #222122;
	padding: 6px;
}
.to-formdivcover {
	width: 100%;
	float: left;
	padding-bottom: 14px
}
.to-formdivcover label {
	width: 100%;
	float: left;
	color: #666666;
	font-size: 12px;
	font-weight: 500;
}
.input-tocover {
	width: 242px;
	float: left
}
.input-tocover input {
	width: 100%;
	float: left;
	height: 33px;
	margin-bottom: 8px;
	font-size: 12px
}
.input-tocover textarea {
	height: 92px;
	width: 100%;
	float: left;
	resize: none;
	font-size: 12px
}
.quantitycover-gift {
	width: 100%;
	float: left;
	padding-bottom: 10px;
	padding-top: 10px
}
.addcart-giftpage {
	width: 100%;
	float: left;
	padding-bottom: 20px
}
.btn-text-note {
	width: 100%;
	float: left;
	padding-bottom: 55px;
	color: #949494;
	font-size: 12px;
}
.chckoutstep6main-cover {
	width: 740px;
	margin: 0 auto;
	background: #fff;
	padding-bottom: 10px;
	padding-top: 30px;
	display: inline-block;
	border-radius: 5px;
	-webkit-box-shadow: 0 2px 3px -1px #babdbf;
	-moz-box-shadow: 0 2px 3px -1px #babdbf;
	box-shadow: 0 2px 3px -1px #babdbf;
}
.smile-signcover {
	width: 100%;
	float: left;
	text-align: center
}
.chckoutstep6main-cover1 {
	float: left;
	text-align: center;
	width: 100%;
	margin-bottom: 22px;
}
.uralsetcover {
	width: 100%;
	float: left;
	text-align: center;
}
.uralset-textdiv {
	width: 100%;
	float: left;
	text-align: center;
	font-weight: 300;
	font-size: 48px;
	color: #666666
}
.uralset-textdiv span {
	color: #222222;
	float: left;
	font-size: 12px;
	font-weight: 400;
	line-height: 2;
	padding-top: 7px;
	text-align: center;
	width: 100%;
}
.ordrnumber-cover {
	width: 100%;
	float: left;
	text-align: center;
	padding-bottom: 40px;
	padding-top: 26px
}
.order-numberinnercover {
	width: 275px;
	height: 53px;
	display: table-cell;
	background: #ebeef0;
	font-size: 24px;
	color: #666;
	vertical-align: middle;
	white-space: nowrap;
	padding: 0 10px;
}
.ordernno-divcenterset {
	display: table;
	margin: 0 auto;
}
.payment-descriptioncover {
	width: 100%;
	float: left;
	text-align: center;
	padding-bottom: 40px
}
.paymentdetail-fixwidth {
	width: 363px;
	margin: 0 auto
}
.paymentamnt-coverdiv {
	width: 100%;
	float: left;
	border-bottom: 1px solid #dfdfdf;
	padding-bottom: 10px;
	font-size: 14px;
	color: #222222;
	padding-bottom: 12px;
	padding-top: 10px;
}
.paymentamnt-coverdiv:last-child {
	border-bottom: none
}
.lefttext-paymnt {
	float: left;
	text-align: right;
	width: 48%;
}
.right-resultamounttxt {
	float: left;
	padding-left: 20px;
	text-align: left;
	width: 52%;
}
.sharepurchasecover {
	width: 100%;
	float: left;
	text-align: center;
	color: #666666;
	padding-bottom: 16px;
	font-size: 27px;
	letter-spacing: -1px;
}
.ahare-purchase-btmtxt {
	color: #222222;
	display: inline-block;
	float: none;
	font-size: 15px;
	line-height: 1.7;
	text-align: center;
	width: 411px;
}
.share-divcover {
	width: 100%;
	float: left;
	padding-bottom: 48px;
	padding-top: 30px;
	text-align: center
}
.kepshopingcoverdiv {
	width: 100%;
	float: left;
	padding-bottom: 50px;
	padding-top: 40px;
	border-top: 1px solid #dfdfdf;
}
.checkout-step6-innercover {
	width: 475px;
	margin: 0 auto;
	display: inline-block;
}
.keepshoping-textcover {
	color: #666666;
	float: left;
	font-size: 28px;
	padding-bottom: 25px;
	text-align: center;
	width: 100%;
}
.kepshopingcoverdiv .btn.btn-default {
	width: 241px;
	height: 53px;
	background: none;
	border: 2px solid #aa00ff;
	color: #666666;
	font-size: 18px;
	border-radius: 5px;
	text-transform: uppercase;
}
.giftcard-imgcover > img {
	border-radius: 10px;
	box-shadow: 0 10px 10px 0 #bbbbbb;
}
/*cocktail-page*/
.cocktail-packagecover {
	margin-bottom: 65px
}
.media-bodyheader {
	background: #fff;
	padding: 15px;
	margin-bottom: 21px
}
.media.media-bodyheader:first-child {
	margin-top: 10px;
}
.cocktail-leftimgdiv {
	width: 284px;
	height: 286px;
	float: left;
	border-radius: 4px;
	overflow: hidden;
	text-align: center;
}
.cockatil-bodycover {
	width: 68%;
	float: left;
	padding-left: 20px;
}
.cocktail-heading {
	width: 100%;
	float: left;
	color: #666566;
	font-size: 24px;
	padding-top: 5px;
	margin-bottom: 0
}
.cocktail-subtitlbtm {
	width: 100%;
	float: left;
	color: #656565;
	font-size: 13px;
	padding: 3px 0;
	margin: 0
}
.moredetail-cocktailtext {
	width: 100%;
	float: left;
	margin: 0;
	padding: 0 0 3px;
}
.product-desccocktail {
	max-width: 84%;
	float: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: #656565;
	font-size: 13px;
	font-weight: 500;
}
.moredetail-cocktailtext a {
	width: 16%;
	color: #aa00ff;
	font-size: 15px;
	margin: 0;
	padding: 0;
	text-decoration: none;
	float: left;
	text-align: right;
	margin: -1px 0 0;
}
.sub-headingcocktail {
	width: 100%;
	float: left;
	color: #656565;
	font-size: 18px;
	font-weight: 500
}
.thumbnail.cocktail-products-div {
	float: left;
	height: 116px;
	overflow: hidden;
	text-align: center;
	width: 93px;
	margin-right: 4px;
	margin-bottom: 5px;
}
.thumbnail.cocktail-products-div .caption {
	width: 100%;
	float: left;
	font-size: 11px;
	color: #222122;
	font-weight: 600;
	padding: 5px 0;
	height: 20px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.cocktail-cartbtn {
	float: left;
	width: 100%;
}
.cocktail-cartbtn .product_addcart, .cocktail-cartbtn .product_addcart:hover {
	float: right;
	margin-top: 0;
	margin-left: 25px
}
.cocktail-cartbtn .product_addcart:hover, .cocktail-cartbtn .product_addcart:active, .cocktail-cartbtn .product_addcart:focus {
	color: #fff
}
.owl-carousel {
	float: left
}
.cocktail-amntdivcover > span {
	font-size: 15px;
	color: #656565;
	float: right;
	margin-top: 9px;
	text-transform: uppercase
}
.amntrupee {
	font-size: 28px;
	color: #585858;
	font-weight: 500;
	float: right;
	padding-left: 5px
}
.cocktail-amntdivcover {
	width: auto;
	float: right;
	line-height: 1.2;
	
}
.media-left.cocktail-leftimgdiv img {
	display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
	border-radius: 4px
}
.navbar-default .navbar-nav li {
	list-style: none
}
.product_addcart, .product_addcart:hover {
	padding-top: 9px
}
/*cocktail-detail*/
.cocktaildetail-cover .media-left {
	width: 350px;
	height: 350px;
	float: left
}
.cocktaildetail-cover .media-left a {
	width: 100%;
	float: left;
	height: 100%;
}
.cocktaildetail-cover .media-left img {
	width: 100%;
	margin: 0
}
.cocktail-detailcontent .media-heading {
	width: 100%;
	float: left;
	font-size: 28px;
	color: #666566;
	font-weight: 500;
}
.media-body.cocktail-detailcontent {
	float: left;
	padding-left: 20px;
	width: 60%;
}
.amntrupee-clsdetail {
	width: 100%;
	float: left;
	color: #585858;
	font-size: 28px;
	font-weight: 500;
	margin-top: 0;
}
.cocktail-detailcontent .cocktaildetail-btmset {
	margin-bottom: 15px
}
.btn.product_addcart.addcart-detail {
	color: #fff;
	font-size: 14.4px;
	font-weight: 600;
	margin-top: 20px;
}
.addcart-btncover {
	width: 100%;
	float: left
}
.btn.product_addcart.addcart-detail > img {
	float: left;
	margin-left: 0;
}
.media-body p {
	font-weight: 500
}
.amnt-savingclr {
	color: #aa00ff;
	font-size: 22px;
}
.checkoutmiddlevlauediv.quantity-detailmarginrmv .pardqantitytexthead {
	margin-top: 10px;
}
.checkoutmiddlevlauediv.quantity-detailmarginrmv {
	margin-top: 0;
}
.ingredintwidthset {
	max-width: 80%;
	color: #676767;
	float: left;
	margin-top: 0;
	padding-left: 12px;
	padding-left: 0
}
.ttile-divcover {
	width: 100%;
}
.cocktaildetail-coverdiv {
	float: left;
	margin-bottom: 13px;
	margin-top: 10px;
	width: 100%;
}
.cocktaildetail-coverdiv span {
	float: left;
	padding-left: 15px;
}
.cocktail-btmborder .byz.ng-scope {
	border-bottom: none !important;
}
.cocktail-btmborder .byz::after, .cocktail-btmborder .byz::before {
	background-color: #cccccc;
	background-image: url("../images/cocktail-accordian-close.png");
	background-position: center center;
	background-repeat: no-repeat;
	height: 22px;
	margin-top: -2px;
	width: 22px;
	left: 0
}
.cocktail-btmborder .byz::before {
	background-image: url("../images/cocktail-accordian-open.png");
	background-repeat: no-repeat;
	background-position: center center;
	transform: none !important;
	background-color: #8d00d4;
}
.marginset-detailpage {
	margin-bottom: 32px;
}
.cocktail-btmborder {
	border-bottom: 1px solid #e3e5e5
}
.cocktail-btmborder:last-child {
	border-bottom: none
}
.detail-productdiv {
	height: 111px;
	margin: 0 auto;
	width: 111px;
	text-align: center;
}
.detail-productdiv > img {
	max-height: 100%;
	margin: 0;
	max-width: 100%;
	display: inline-block;
	max-width:100%;
}
.btm-contetnrecipie {
	width: 100%;
	border-bottom: 1px solid #e3e5e5;
}
.recipie-titlediv {
	width: 100%;
	float: left;
	color: #000;
	font-size: 15px;
	font-weight: 500;
	padding-left: 40px
}
.cocktail-detailpagecover span {
	color: #363535;
	font-size: 12px;
}
.product-cocktailtitle {
	font-size: 13px !important
}
.btm-contetnrecipie span {
	font-size: 13px;
	color: #69696a;
	width: 100%;
	display: inline-block;
	line-height: 1.7;
	white-space: pre-line;
}
.paddingrmvcls-cocktail {
	padding-left: 0
}
.cocktail-btmborder.reipie-marginset-titl .byz {
	margin: 12px 0 30px
}
.productdetailbrudcumcover.cocktail-detailpagecover.ng-scope {
	margin-bottom: 55px;
	margin-top: 10px;
	padding: 0
}
.ingrdients-tabpadingrmv {
	padding-top: 10px;
	padding-left: 29px;
	padding-right: 29px;
}
.cocktail-detailpagecover md-tabs-wrapper {
	padding-left: 25px;
	padding-right: 25px;
}
.cocktail-detailpagecover .md-tab {
	padding: 17px 24px
}
/*pop-up*/
.dialogdemoBasicUsage div#status {
	color: #c60008;
}
.dialogdemoBasicUsage #popupContainer {
	position: relative;
}
.dialogdemoBasicUsage .footer {
	width: 100%;
	text-align: center;
	margin-left: 20px;
}
.dialogdemoBasicUsage .footer, .dialogdemoBasicUsage .footer > code {
	font-size: 0.8em;
	margin-top: 50px;
}
.dialogdemoBasicUsage button {
	width: 200px;
}
.dialogdemoBasicUsage div#status {
	color: #c60008;
}
.gift-packagecoverdiv {
	background: #fff url(../images/popup-gift-bg.png) no-repeat;
	border-radius: 4px;
	background-size: cover;
	padding-bottom: 5px;
}
.gift-packgng-bodydiv {
	width: 100%;
	float: left;
	padding-left: 32px;
	padding-top: 50px;
	text-align: center;
}
.gift-item-cover {
	width: 151px;
	height: 140px;
	display: inline-block;
	float: none;
	text-align: center;
	background: #ffc412;
	border-radius: 4px;
	margin-right: 30px;
}
.gift-item-cover:last-child {
	margin-right: 0
}
.gift-item-innercover {
	display: table;
	float: left;
	height: 90px;
	width: 100%;
}
.gift-itemimg {
	display: table-cell;
    height: 90px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    padding: 5px 0;
}

.gift-itemimg img{
	max-width: 75%;
	/*max-height: 75%;*/
}


.gift-item-title {
	width: 100%;
	float: left;
	text-align: center;
	font-size: 20px;
	color: #000;
	height: 50px;
	line-height: 1;
}
.gift-topcrosicondiv {
	width: 100%;
	float: left
}
.gift-topcrosicondiv img {
	float: right;
	padding-top: 20px;
	padding-right: 20px
}
.navbar-nav.party-cocktail-liset {
	padding-left: 10px;
}
.party-package-coverdiv {
	width: 100%;
	float: left
}
.left-party-content {
	width: 48.8%;
	float: left;
	background: #fff none repeat scroll 0 0;
	margin-bottom: 20px;
	border-radius: 4px
}
.party-topimgdiv {
	width: 100%;
	float: left;
	height: 450px;
	overflow: hidden;
	position: relative;
	border-radius: 4px 4px 0 0;/*position: relative;*//*	transition:all ease .5s ;
*/
}
.left-party-content.ng-scope {
	position: relative;
}
.left-party-content.ng-scope:hover .part-content-btm-desc .xyz.ng-scope.ng-isolate-scope {
	float: left;
}
.part-content-btm-desc {
	width: 100%;
	float: left;
	padding: 16px 18px 0;
	border: 1px solid #dee2e5;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 2px 2px -3px rgba(0, 0, 0, 0.5)
}
.left-party-content.right-party-content {
	float: right;
}
.party-leftposion {
	width: 100%;
	float: left;
	padding-top: 14px;
	padding-bottom: 16px;
	border-bottom: 1px solid #dee2e5;
}
.package-amtcover {
	width: 30%;
	float: left
}
.saving-amount-cover {
	width: 30%;
	float: left;
	padding-left: 35px;
	padding-top: 3px;
}
.add-cart-cover {
	width: 30%;
	float: left
}
.package-contain-cover {
	width: 100%;
	float: left;
	padding-top: 8px
}
.package-contain-titl {
	color: #676767;
	font-size: 18px;
	font-weight: 500;
	width: 100%;
	float: left;
	padding-bottom: 20px
}
.package-inner-cover5 {
	width: 100%;
	float: left;
	padding-bottom: 10px;
}
.ttile-divcover-main {
	float: left;
	padding-bottom: 7px;
	width: 100%
}
.ttile-divcover-main > span {
	color: #a9a8a9;
	display: inline-block;
	font-size: 13px;
	width: 52%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ttile-divcover-main .custmize-contntset {
	width: 48%
}
.party-detail-heading {
	width: 100%;
	float: left;
	color: #666666;
	font-size: 28px;
	font-weight: 500;
	padding-top: 3px;
	padding-bottom: 3px;
}
.party-detail-btm-title {
	width: 100%;
	float: left;
	color: #000;
	font-size: 18px;
	font-weight: 500
}
.party-product-desc {
	width: 100%;
	float: left;
	color: #666666;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.6;
	padding-bottom: 14px;
}
.party-detail-amt {
	color: #000
}
.quantiy-party-detail {
	width: 100%;
	float: left
}
.media-body.party-detail-contentcover {
	padding-left: 20px;
}
.party-qtn-titl {
	margin-top: 0
}
.add-cart-btm {
	padding-bottom: 24px
}
.party-detail-btmtitl4 {
	padding-left: 16px
}
.party-deatil-pading5 {
	padding-left: 13px
}
/* Reset responsive Bootstrap elements */
#navbar2 .navbar-header {
	float: none;
}
#navbar2 .navbar-toggle {
	display: block;
}
#navbar2 .navbar-nav {
	float: none !important;
	margin: 7.5px -15px;
}
#navbar2 .navbar-nav .open .dropdown-menu {
	position: static;
	float: none;
	background-color: transparent;
	border: 0;
	box-shadow: none;
}
#navbar2 .navbar-form {
	float: none !important;
	padding: 0;
}
#navbar2 .navbar-nav>li {
	float: none;
}
/* Reposition elements affected by the sliding menu */

#navbar2 .navbar-collapse {
	position: fixed;
	top: 0;
	left: -30%;
	display: block;
	width: 30%;
	height: 100% !important;
	margin: 0;
	background-color: #f8f8f8;
	transition: right 0.35s ease;
}
#navbar2 .navbar-collapse.collapsing {
	transition: right 0.35s ease;
}
#navbar2 .navbar-collapse.in {
	right: 0;
}
body.menu-slider.in {
	overflow: hidden;
}
#bs-example-navbar-collapse-1 {
	display: block
}
#page-content-wrapper {
	width: 100%;
	padding-top: 0;
}
.hamburger {
	background: transparent none repeat scroll 0 0;
	border: medium none;
	display: block;
	height: 27px;
	margin-left: 15px;
	position: fixed;
	top: 30px;
	width: 26px;
	z-index: 999;
}
.hamburger:hover, .hamburger:focus, .hamburger:active {
	outline: none;
}
.hamburger.is-closed:before {
	content: '';
	display: block;
	width: 100px;
	font-size: 14px;
	color: #fff;
	line-height: 32px;
	text-align: center;
	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover:before {
	opacity: 1;
	display: block;
	-webkit-transform: translate3d(-100px, 0, 0);
	-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed .hamb-top, .hamburger.is-closed .hamb-middle, .hamburger.is-closed .hamb-bottom, .hamburger.is-open .hamb-top, .hamburger.is-open .hamb-middle, .hamburger.is-open .hamb-bottom {
	position: absolute;
	left: 0;
	height: 3px;
	width: 100%;
}
.hamburger.is-closed .hamb-top, .hamburger.is-closed .hamb-middle, .hamburger.is-closed .hamb-bottom {
	background-color: #2c2011;
	opacity: 0.7;
}
.hamburger.is-closed .hamb-top {
	top: 5px;
	-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed .hamb-middle {
	top: 50%;
	margin-top: -2px;
}
.hamburger.is-closed .hamb-bottom {
	bottom: 5px;
	-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-top {
	top: 5px;
	-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
	bottom: 5px;
	-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open .hamb-top, .hamburger.is-open .hamb-middle, .hamburger.is-open .hamb-bottom {
	background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top, .hamburger.is-open .hamb-bottom {
	top: 50%;
	margin-top: -2px;
}
.hamburger.is-open .hamb-top {
	-webkit-transform: rotate(45deg);
	-webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
}
.hamburger.is-open .hamb-middle {
	display: none;
}
.hamburger.is-open .hamb-bottom {
	-webkit-transform: rotate(-45deg);
	-webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
}
.hamburger.is-open:before {
	content: '';
	display: block;
	width: 100px;
	font-size: 14px;
	color: #fff;
	line-height: 32px;
	text-align: center;
	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
	opacity: 1;
	display: block;
	-webkit-transform: translate3d(-100px, 0, 0);
	-webkit-transition: all .35s ease-in-out;
}
/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/

.overlay {
	position: relative;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}
.ancontainer {
	z-index: 9999
}
.party-hover-effect {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: rgba(0,0,0,.2);
	transition: all .5s ease;
	display: none;
	height: 100%;
	width: 100%;
	overflow: hidden;
	opacity: 0
}
.left-party-content:hover .party-hover-effect, .cock-party-content:hover .party-hover-effect {
	background-color: rgba(0,0,0,.3);
	display: block;
	cursor: pointer;
	opacity: 1;
	top: 0
}
.left-party-content:hover, .cock-party-content:hover {
	-webkit-box-shadow: 0px 3px 10px 6px #b2bfc9;
	-moz-box-shadow: 0px 3px 10px 6px #b2bfc9;
	box-shadow: 0px 3px 10px 6px #b2bfc9;
}
.customize-inner {
	display: table-cell;
	vertical-align: middle;
}
.customizebtn {
	display: table;
	height: 100%;
	text-align: center;
	width: 100%;
}
.customizebtn .customize-inner .btn.btn-default {
	background: #aa00ff none repeat scroll 0 0;
	border: medium none;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase
}
.customize-inner .btn.btn-default > span {
	padding-left: 7px;
}
.customize-text-cover {
	float: right;
	cursor: pointer
}
.customize-text-cover > span {
	padding-left: 10px;
	color: #aa00ff;
	text-transform: uppercase;
	font-size: 14px;
}
.saving-cocktail-cover {
	clear: both;
    color: #aa00ff;
    float: left;
    font-size: 15px;
    width: auto;
}
.saving-cocktail-cover span {
	float: left;
	text-transform: uppercase;
	width: auto;
}
.saving-titie-amnt {
	float: right;
    margin-left: 19px;
    width: auto;
	
}
.btn.product_addcart.addcart-detail.cocktail-cart-margintop {
	margin-top: 0
}
.btn.product_addcart.addcart-detail.party-cart-margintop {
	margin-top: 10px
}
.packag-amnt-divcolr {
	color: #656565
}
.package-amnt-price-color {
	color: #222122
}
.alcohaladdadremapbody .col-sm-4 > div {
	display: inline-block;
	width: 100%;
}
.div_addsboxs.ng-scope {
	height: 200px;
}
.saving-amount-cover .packtiti-rightset {
	font-size: 15px
}
.saving-amount-cover.saving-detail-padding {
	padding-left: 0
}
.cktail-titl-line {
	line-height: 1.2
}
.cocktail-btm-padingrmv {
	padding-top: 0
}
.gry_text_div {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.add_alltext {
	height: 83px
}
.dropdown.cart-icon-set {
	
	padding-right: 2px;
	position: relative
}
.dropdown.cart-icon-set span {
	padding: 0 5px;
	/*cursor:default;*/
}
.navbar-shrink .dropdown.cart-icon-set span, .navbar-shrink .dropdown.cart-icon-set span:hover, .navbar-shrink .dropdown.cart-icon-set span:focus {
	padding: 0 5px;
	display: table-cell
}
.credis-table-main {
	width: 100%;
	float: left
}
.rightplcholder {
	position: absolute;
	right: 45px;
	top: 13px;
	color: #d9a600;
	font-size: 12px;
	z-index: 9999;
	margin-right: 10px;
}
.searchtop.searchtop100 .search_icon {
	margin-right: 0
}
/*.addmore-count.ng-binding {
	position: relative;

	top: 0px !important;
}*/


.quantity-inputsetspan .addmore-count.ng-binding {
	top: -2px !important
}
.btn.btn-default.bootstrap-touchspin-down {
	position: relative;
	top: 0;
}
.btn.btn-default.bootstrap-touchspin-up {
	margin-top: 0px;
}
#menu-toggle {
	display: none;
}
#sidebar-wrapper {
	display: none;
}
.checkouter-td-right-text > div {
    float: left;
}
.cms-leftmenu{
	display:none;
}
.recentorder md-checkbox{
	width:65px;
	height:100%;
}
.recentorder md-checkbox .md-container {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto;
    right: 0 !important;
    top: 0 !important;
	 transform: inherit !important; 
}

.desktop-showblock{
	display: block;
}
.responsive-blokimg{
	display: none;
	width: 100%;
	float: left;
	text-align: center;
}
.desktop-show-product-des{
	display: block;

}
.responsive-show-product-des{
	display: none;
}
.closemenu{
	display: none;

}
#dont-miss-popup{
	max-height: 100% !important;

}
.dont-miss-res-show{
	display: none;
	width: 100%;
	float: left;
	text-align: right;
	margin-bottom: 20px;
}
.prod-in-slider{
	display: block !important; 
}
.dont-miss-res-show .alcohalosavebntcvr{
	float: none;

}
.res-show{
	display: none;
}
.package-detail-new-box{
	float: left;
}
.responsive-show{
	display: none;
}
.desk-show{
	visibility: visible;
}
.respoonsive-show-chilled{
	display: none;
}
.checkbox-gray-out{
	padding:0px 0px;
	width: 100%;
	float: left;
}
@media (min-width : 1024px) and (max-width : 1200px) {
  .christmas-mainouter.col-md-8 {
	  padding-left: 0;
	  padding-right: 0;
  }
.proHoverEffect:hover {
    box-shadow: 0 0 5px 2px #d6dce0;
}
  .tabtitle {
	  padding-left: 15px;
	  padding-top: 6px;
	  width:23%;
  }
/*  .christmas-mainouter .combo_slider .item {
	  margin: 0 4px;
	  position: relative;
	  top: 24px;
  }*/
  .single_slider {
	  background-size: cover
  }
  /*.item.ng-scope {
	  display: inline-block;
	  float: none;
	  margin: 0;
	  text-align: center;
	  width: 100%;
  }*/
  /*.item.ng-scope div {
	  display: inline-block;
	  float: none;
	  text-align: center;
  }*/
  /*.slido_left {
	  margin-top: 20px;
	  width: 100%;
  }*/
  .slido_right.ng-binding {
	  float: left;
	  font-size: 15px;
	  margin-top: 20px;
	  padding: 0 7px;
	  text-align: center;
	  width: 100%;
  }
  .footer2_madetxt {
	  margin-bottom: 0;
  }
  .footer2_copytxt {
	  margin-bottom: 40px;
  }
  .ancontainer {
	  z-index: 9999
  }
  .modal-dialog.alcohal_addadressmain {
	  width: 100%;
  }
  /*md-dialog {
	  max-height: 90%;
	  max-width: 90%;
	  overflow-y: auto;
	  overflow-x: hidden;
	  opacity: 1;
  }*/
  .pushy.pushy-left {
	  display: none
  }
  #container {
	  display: none
  }
  .signuplogin a, .signuplogin > a:hover, .signuplogin a:focus {
	  /*padding-left: 9px !important;
	  padding-right: 9px !important;*/
  }
  .signuplogin_cover {
	  max-width: 42%;
	  width: 42%
  }
  .addressbokk-trvaichacovr {
	  width: 42%
  }
  .addressbokk-addnewadresscovr {
	  width: 42%
  }
  .checkouter-td-right-text {
	  display: inline-block;
	  float: none;
	  width: 67%;
  }
  .checkouterwantbutton {
	  width: 240px
  }
  .step1 {
	  left: 23px;
  }
  .step2 {
	  left: 158px;
  }
  .step3 {
	  left: 304px;
  }
  .step4 {
	  left: 447px;
  }
  .step5 {
	  right: 27px;
  }
  .ro_desc {
	  width: 66%;
	  padding: 0;
	  font-size:11px;
  }
  .ro_items {
	  padding: 0
  }
  .viewbtns {
	  width: 68px
  }
 
  .addbtn img {
	  display: inline-block
  }
  
  .repeatbtn img {
	  display: inline-block
  }
  #smokeneedpanel td {
	  color: #d6b8ee;
	  display: inline-block;
	  font-size: 1em;
	  padding: 0;
  }
  #smokeneedpanel md-switch {
	  width: 50px;
	  margin: 25px 2px;
  }
  .step5 {
	  right: 0;
	  top: 13px !important;
  }
  .steps_notify .step_grid a {
	  white-space: normal;
	  word-wrap: break-word;
  }
  .steps_notify .step_grid a span {
	  width: 90px
  }
  .coverboxsegment_div {
	  margin-bottom: 20px;
  }
  .footer-container-borderset {
	  width: 94%
  }
  .alcohal_hisryorderboxcvr1:nth-child(2n+2) {
	  margin-right: 19px;
  }
  .alcohal_hisryorderboxcvr1:nth-child(3n+3) {
	  margin-right: 0;
  }
  .alcohal_hisryorderboxcvr1 {
	  width: 226px
  }
  .comboproductitems_outofstock {
	  padding: 0;
	  width: 49%;
  }
  .product-detail-right-set {
	  padding: 0;
	  width: 51%;
  }
  .productdetailcovermainbgimg.ng-scope {
	 /* padding-left: 10px;
	  padding-right: 10px;*/
  }
  
 
  .dropdown.productdropdownbtn {
	  margin-right: 0;
	  margin-top: 0;
  }
  .dropdown.productdropdownbtn span1 {
	  float: left;
	  position: relative;
	  right: 0;
  }
  
  .owl-wraaper-margin .owl-wrapper {
	  margin-bottom: 33px;
  }
  .comboproductitems {
	  width: 100%
  }
   .product-listcover2 .comboproductitems{
	  width:33.33%;
	  margin:30px 0px;
  }
  .prod_desc.ng-binding {
	  height: 40px;
	  margin-bottom: 0;
	  overflow: hidden;
  }
  .single_slider.single_slider_product.ng-scope {
	  padding-left: 13px;
	  padding-right: 13px;
  }
  /*.single_slider.ng-scope .owl-item {
	  width: 207px !important;
  }*/
  .productdropdownbtn .dropdown-menu {
	  left: 0
  }
  .single_slider_product .owl-controls {
	  width: 100%;
	  text-align: center;
	  display: inline-block
  }
  .saving-amount-cover {
	  float: left;
	  padding-left: 0;
	  padding-top: 3px;
	  width: 50%;
  }
  .package-amtcover {
	  float: left;
	  width: 50%;
  }
  .add-cart-cover {
    float: left;
    width: 100%;
}
  .amntrupee-clsdetail {
	  font-size: 21px
  }
  .btmcontn-set > a {
	  width: 31%;
  }
  .package-desc.ng-binding {
	  width: 69%;
  }
  
  .cockatil-bodycover {
	  width: 58%
  }
  .product-desccocktail.ng-binding {
	  max-width: 75%;
  }
  .moredetail-cocktailtext > a {
	  width: 25%;
  }
  .party-detail-btm-width {
	  width: 33.33%;
	  float: left;
  }
  .edit_del_icondiv {
	  display: block;
  }
  .homecallus_cover {
	  width: 19%;
  }
  .fullwidth.top-tabmainouter > .right-crousel {
	  float: left;
	  margin-bottom: 20px;
	  padding-left: 5px;
	  padding-right: 5px;
	  width: 75%;
	  margin-right: 0
  }
  /*.tab-content.fullwidth .owl-item {
	  width: 159px !important;
	  position: relative;
	  top: -20px;
  }*/
  /*.top-tabmainouter .owl-item {
	  width: inherit;
	  position: relative;
	  top: -16px;
  }*/
  .top-tabmainouter .owl-prev {
	  left: -10px;
	  top: 2px;
  }
  .top-tabmainouter .owl-next {
	  right: -10px;
	  top: 2px;
  }
/*  .slido_left {
	  position: relative;
  }
  .slido_right {
	  min-height: inherit
  }*/
  .slido_right.ng-binding {
	  padding: 10px 7px
  }
  .christmas-mainouter .combo_slider .tab-content .item {
   top:0;
  }
  .searchtop.searchtop100 {
	  top: 39px;
  }
  .comboproductitems .add-wishlist-icondiv {
	  display: block
  }
  .comboproductitems .bulk-dis-icon {
	  display: block
  }
  .add-icon-main {
	  position: relative
  }
  .brands .owl-theme .owl-controls{
	margin-top:20px;
}
.footer1_title{
	font-size:13px;
	margin-bottom:0px;
	min-height:50px;
}
.footer1_subtitle ul li a{
	font-size:13px;
}
.alcohalhdr h2{
	display:none;
}
.innerpagemargintop p{
	text-align:justify;
}
.pic_divinnertext{
	width:90%;
}
.addressbook-btmpannelcover .addressbokk-trvaichacovr:nth-child(3n){
	margin-right:17px;
}
.addressbook-btmpannelcover .addressbokk-trvaichacovr,.addressbook-btmpannelcover.cartaddresses .addressbokk-trvaichacovr {
    margin: 10px 17px 10px 0;
}
.addressbokk-trvaichacovr.ng-scope:nth-of-type(2n+2) {
    margin-right: 0 !important;
}
.profile_form{
	width:72%;
}
.checkboxtotaldiv-title{
	width:86%;
}
.checkoutstep5-mid-left {
    float: left;
    width: 60%;
}
.checkoutstep5-mid-right {
    float: left;
    width: 40%;
}
.laeftdivmain {
    float: left;
    width: 25%;
}
.rgrhtdiv {
    width: 75% !important;
}
.checkoutmiddletable table td{
	padding-left:6px !important;
	padding-right:7px !important;
}
.salediv{
	text-align:left !important;
}
.checkoutrighbuttonadd{
	width:52px;
}
.checkoutrighbuttoncancel{
	width:50px;
}

.occassion_sale{
	font-size:120px;
}
.flex-40, .layout-row > .flex-40 {
    max-height: 100%;
    max-width: 50%;
}
.repeatbtn{
	float:left;
	margin-bottom:10px;
}
.no-image.prod_pic{
	background-size:auto;
}
.productdetailbrudcumcover iframe{
	width:100%;
	
}
.comboproductdetailitems{
	width:25%;
}
.adresfloortxtcvr{
	width: 45%;
}
.adressunit{
	width: 44%;
}
.alcohal_adress2fotter{
	width:72%;
}
.combo_sliderProduct .maintab{
	overflow: auto;
}
.combo_sliderProduct .md-dynamic-height{
	overflow: hidden;
}
.prod_chilled{
	min-width: 90px;
}
.cartstepone-cover .owl-carousel .owl-item {
    width: 210px !important;
}
}

@media (min-width : 768px) and (max-width : 1023px) {
  .col-md-2.homecallus_cover {
	  display: none;
  }
  .logolarge {
	  left: 47%;
	  width: 171px;
  }
  .logoss {
	  width: 42%;
	  display: inline-block;
	  float: none;
  }
  .navbar-fixed-top .signuplogin ul.menuulpadding > li:nth-child(2) {
	  display: none;
  }
  .navbar-fixed-top .signuplogin ul.menuulpadding > li:nth-child(1) {
	  display: none;
  }
  /*.navbar-fixed-top .signuplogin ul.menuulpadding > li:nth-child(3) {
	  display: none;
  }*/
  .navbar-fixed-top .signuplogin ul.menuulpadding > li:nth-child(4) {
	  display: table;
  }
  .signuplogin_cover {
	  width: 23%
  }
  .side-menu-responsive {
	  left: 0;
	  position: absolute;
	  top: 0;
	  z-index: 9999;
	  display: none
  }
  .occassion_sale {
	  float: left;
	  font-size: 100px;
	  line-height: normal;
	  padding-bottom: 40px;
	  padding-top: 60px;
  }
  .tabtitle {
	  width: auto
  }
  #bs-example-navbar-collapse-1 {
	  display: none
  }
  .item.ng-scope {
	  display: inline-block;
	  float: none;
	  margin: 0;
	  width: 100%;
  }
/*  .item.ng-scope div {
	  display: inline-block;
	  float: none;
	  text-align: center;
  }*/
  .registernowbtn.ng-scope {
	  margin: 20px 0;
  }
  .prod_pic {
	  margin-bottom: 15px;
  }
  .prod_desc.ng-binding {
	  height: auto;
	  margin-bottom: 0;
  }
  
  .offers {
	  width: 100%
  }
  .offrates {
	  width: 49%;
	  margin-bottom: 10px;
  }
  .offrates_txt {
	  font-size: 48px
  }
  .happy_hours {
	  width: 49%
  }
  .fullwidth.ng-scope.header {
	  margin-bottom: 0;
  }
  .single_slider.ng-scope {
	  width: 100%;
	 
	  background-size: cover
  }
/*  .slido_right {
	  float: right;
	  font-size: 15px;
	  font-weight: 500;
	  margin-top: 10px;
	  padding: 0 20px;
	  width: 100%;
  }*/
  .single_slider.ng-scope .owl-carousel .owl-item {
	  width: 100%;
  }
/*  .slido_left {
	  margin-top: 0;
	  width: 100%;
  }
  .slido_right {
	  font-size: 15px;
	  font-weight: 500;
	  margin-top: 10px;
	  padding: 0 20px;
	  width: 100%;
  }*/
  .orderbooz {
	  margin-top: 20px
  }
  .socio_btns {
	  margin-top: 30px
  }
  .fullwidth.social_block {
	  padding: 20px 0;
  }
  .socilblok_twtr {
	  margin-left: 0;
	  text-align: center;
	  width: 27%;
	  margin: 5px 0
  }
  .socilblok_fb {
	  margin-left: 0;
	  text-align: center;
	  width: auto;
	  margin-top:5px;
  }
  .socilblok_folowtxt {
	  margin-left: 0;
	  text-align: center;
	  width: 49%;
	  padding-top:9px;
  }
  .socilblok_twtr img {
	  display: inline-block;
	  float: none;
  }
  .socilblok_twtr > a > span {
	  display: inline-block;
	  float: none;
	  padding-left: 0;
  }
  .socilblok_fb img {
	  display: inline-block;
	  float: none;
  }
  .socilblok_fb > a > span {
	  display: inline-block;
	  float: none;
	  padding-left: 0;
  }
  .footer1_pannel1cvr {
	  width: 30%
  }
  .footer1_sociallink {
	  margin: 20px 0;
  }
  .footer1_privcytext {
	  width: 42%
  }
  .footer1_pannel2cvr {
	  width: 20%
  }
  .footer1_pannel3cvr {
	  width: 20%
  }
  .footer1_pannel4cvr {
	  width: 28%
  }
  .footer2_btmcvr {
	  margin-top: 20px
  }
  .footer2_madetxt {
	  margin-top: 30px;
  }
  .footer2_copytxt {
	  margin-top: 30px;
  }
  .leftphone {
	  max-width: 126%
  }
  .onthego_block {
	  background-size: cover
  }
  .dropdown-menu a:focus .nav.sidebar-nav li a {
	  background: none !important
  }
  .dropdown-menu a:hover .nav.sidebar-nav li a {
	  background: none !important
  }
  .dropdown-menu a:active .nav.sidebar-nav li a {
	  background: none !important
  }
  /*.ancontainer{
		z-index:2
	}*/
  .brands .owl-carousel .owl-item {
	  display: table-cell;
	  float: none;
	  max-width: 100%;
	  padding-bottom: 10px;
	  text-align: center;
	  vertical-align: middle;
  }
  .single_slider.ng-scope {
	  margin-bottom: 30px;
  }
  .searchtop {
	  right: 20%
  }
  .searchtop100 {
	  right: 15px;
	  width: 88%;
  }
  .onthego {
	  font-size: 110px
  }
  .phone-img-responsive {
	  float: left;
	  width: 30%;
  }
  .ordr-boz-cover {
	  float: left;
	  width: 40%;
  }
  .payment-widthset {
	  float: left;
	  width: 30%;
  }
  .footer2_madetxt {
	  margin-bottom: 0;
  }
  .footer2_copytxt {
	  margin-bottom: 40px;
  }
  .ancontainer {
	  z-index: 9999
  }
  .searchtop.searchtop100 {
	  top: 48px;
  }
  .searchopen .hamburger {
	  display: none
  }
  .searchtop.searchtop100 {
	  float: left;
	  margin-top: -10px;
	  right: 0;
	  width: 100%;
  }
  /*.currentorderpopup {
	  left: -203px;
	  width: 266px;
  }
  .currentorderpopup::before {
	  left: 79%
  }*/
  .modal-dialog.alcohal_registermain {
	  float: none;
	  text-align: center;
	  width: 83%;
	  float: none;
	  margin: 10px auto;
  }
  .alcohal_regisemailcvr {
	  width: 73%
  }
  .gift-packagecoverdiv {
	  width: 100%
  }
  .gift-itemimg {
	  padding-top: 5px
  }

  .gift-itemimg img{
	max-width: 75%;
	/*max-height: 75%;*/
  }
  .modal-dialog.alcohal_addadressmain {
	  width: 100%;
  }
  /*md-dialog {
	  max-height: 90%;
	  max-width: 90%;
	  overflow-y: auto;
	  overflow-x: hidden;
	  opacity: 1;
  }*/
  .gift-item-cover {
	  display: inline-block;
	  float: none;
	  margin: 0 5px 15px auto;
  }
  .gift-item-cover:last-child {
	  margin: 0 5px 15px auto;
  }
  .pushy.pushy-left {
	  display: block
  }
  #container {
	  display: block
  }
  .dropdown.cart-icon-set span {
	  display: inline-block !important;
  }
  .rgrhtdiv {
	 
	  width: 100% !important;
  }
  .laeftdivmain {
	  float: left;
	  width: 26%;
  }
  .registeremailcvr {
	  margin-bottom: 15px
  }
  .balancetexxt {
	  width: 100%
  }
  .titlecreidt {
	  margin-bottom: 10px
  }
  .balancedetailsdiv2 {
	  width: 100%;
	  margin-bottom: 20px;
  }
  .profile_form {
	  width: 100%
  }
  .order-history .-status {
	  padding: 8px 13px
  }
  .wishlist-text {
	  width: 100%;
	  margin-bottom: 30px
  }
  .addressbokk-trvaichacovr.ng-scope {
	  width: 48.5%;
	  margin-left: 0;
	  padding: 25px 13px;
  }
  .addressbokk-trvaichacovr:nth-child(2) {
	  margin-right: 0
  }
  .addressbokk-addnewadresscovr {
	  margin: 10px 10px 10px 0;
	  min-height: 145px;
	  padding: 20px 15px;
	  width: 48.5%;
	  margin-right: 0;
	  min-height: 155px
  }
  .orhistry2_td2procdtcvr > span {
	  margin-right: 0;
  }
  .checkouter-td-right-text {
	  width: 73%;
	  padding-left:10px;
  }
  .step5 {
	  right: 29px;
  }
  .steps_notify .step_grid a span {
	  width: 96px
  }
  .coverboxsegment_div {
	  margin-bottom: 20px;
  }
  .footer-container-borderset {
	  width: 100%
  }
  .right-smoke-main .fullwidth > table {
	  display: inline-block;
	  width: 100%;
  }
  .right-smoke-main .fullwidth tbody {
	  display: inline-block;
	  width: 100%;
  }
  .right-smoke-main .fullwidth tr {
	  float: left;
	  margin: 0 auto;
	  text-align: center;
	  width: 100%;
  }
  .right-smoke-main .fullwidth td {
	  display: inline-block;
  }
  .right-smoke-main.ng-scope {
	  float: left;
	  margin-bottom: 20px;
	  width: 100%;
  }
  .alcohal_hisryorderboxcvr1 {
	  width: 254px;
  }
  .alcohal_hisryorderboxcvr1:nth-child(2n+2) {
	  margin-right: 0;
  }
  .alcohal_hisryorderboxcvr1:nth-child(3n+3) {
	  margin-right: inherit;
  }
  .alcohal_hisryorderboxcvr1.ng-scope:nth-child(2n+1) {
	  margin-right: 19px;
  }
  .comboproductitems_outofstock {
	  padding: 0;
	  width: 48%;
  }
  .product-detail-right-set {
	  padding: 0;
	  width: 50%;
  }
  .productdetailcovermainbgimg.ng-scope {
	  /*padding-left: 10px;
	  padding-right: 10px;*/
  }
  .prdquantitybulkcoverbulk {
	  float: left;
	  width: 74%;
  }
  .prddetailtitle.ng-binding {
	  font-size: 25px;
  }
  .productinner_rightslide{
	  padding-top:0px;
	}
  /*.left-main-outerdiv .productslide_heading.ng-binding {
	  font-size: 17px;
	  height: 47px;
	  line-height: 24px;
	  overflow: hidden;
	  padding: 0 5px;
  }*/
  .dropdown.productdropdownbtn {
	  margin-right: 0;
	  margin-top: 0;
  }
  .dropdown.productdropdownbtn span1 {
	  float: left;
	  position: relative;
	  right: 0;
  }
 /* .productslide_normaltext.ng-binding {
	  height: 33px;
	  overflow: hidden;
	  padding: 0 5px;
  }*/
  .owl-wraaper-margin .owl-wrapper {
	  margin-bottom: 33px;
  }
  .comboproductitems {
	  width: 100%
  }
  .product-listcover2 .comboproductitems{
	  width:25%;
	  margin:30px 0px;
  }
  .prod_desc.ng-binding {
	  height: 40px;
	  margin-bottom: 0;
	  overflow: hidden;
  }
  .productdropdownbtn .dropdown-menu {
	  left: 0
  }
  .prdquanbulkboxone {
	  width: 60px;
  }
  .single_slider_product .owl-controls {
	  width: 100%;
	  text-align: center;
	  display: inline-block
  }
  .saving-amount-cover {
	  float: left;
	  padding-left: 0;
	  padding-top: 3px;
	  width: 50%;
  }
  .package-amtcover {
	  float: left;
	  width: 50%;
  }
  .add-cart-cover{
	   width: 100%;
	 }
  .btmcontn-set > a {
	  width: 31%;
  }
  .package-desc.ng-binding {
	  width: 69%;
  }
  .amntrupee-clsdetail {
	  font-size: 21px
  }
  .cockatil-bodycover {
	  width: 58%
  }
  .product-desccocktail.ng-binding {
	  max-width: 75%;
  }
  .moredetail-cocktailtext > a {
	  width: 25%;
  }
 
  .cocktail-detail-width {
	  width: 25%;
	  float: left
  }
  .party-detail-btm-width {
	  width: 33.33%;
	  float: left;
  }
  /*.navbar.navbar-default.navbar-fixed-top.navbar-shrink .signuplogin .dropdown:hover .cart_icon {
	  background: #e1b11f url("../images/sprite.png") no-repeat scroll -338px 25px;
  }*/
  .edit_del_icondiv {
	  display: block;
  }
  .navbar-fixed-top.navbar-shrink .hamburger {
	  top: 23px;
  }
  .col-md-10.top-logo-sideacnt-cover.text-center {
	  width: 100%
  }
  .hamburger {
	  left: 15px;
	  top: 40px;
  }
  .img-responsive.logolarge {
	  position: relative;
	  top: 0
  }
  .img-responsive.logosmall {
	  position: relative;
	  top: 0;
  }
  #menu-toggle {
	  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	  border: medium none;
	  display: inline-block;
	  padding: 0;
	  position: absolute;
	  top: 40%;
	  z-index: 9999;
  }
  .innerpagemargintop {
	  margin-top: 110px;
  }
  .fullwidth.top-tabmainouter > .right-crousel {
	  float: left;
	  margin-bottom: 20px !important;
	  padding-left: 5px;
	  padding-right: 5px;
	  width: 79%;
	  margin-right: 0
  }
  .tab-content.fullwidth .owl-item {
	  width: inherit;
  }
  .top-tabmainouter .owl-item {
	  width: inherit;
	  position: relative;
	  top: 8px;
  }
  /*.slido_left {
	  position: relative;
  }*/
  .tabtitle {
	  padding-top: 13px
  }
 /* .slido_right {
	  min-height: inherit
  }*/
  .top-tabmainouter .owl-prev, .top-tabmainouter .owl-next{
  	top: 10px;
  }
  .slido_right.ng-binding {
	  padding: 10px 7px
  }
  .text-capitalize {
   font-size:14px;
  }
  .btm10.ng-scope.searchopen .menu-icon {
	  display: none
  }
  .navbar-fixed-top.navbar-shrink .searchtop {
	  top: 35px;
  }
  .comboproductitems .add-wishlist-icondiv {
	  display: block
  }
  .comboproductitems .bulk-dis-icon {
	  display: block
  }
  .prdquanbulkboxtwo {
	  padding-left: 6px;
	  padding-right: 5px
  }
  .add-icon-main {
	  position: relative
  }


.currentorderpopup{
	left: auto;
	right: 0px;
}
.currentorderpopup::before {
    left: calc(81% - 0px);
}

.cartbtn{
	/*margin: 0 auto;*/
	float: none;
}




.navbar-default .navbar-nav li a{
	font-size: 18px;
	padding: 15px 0;
}

.navbar-default .navbar-nav .dropdown-menu a{
	padding: 15px !important;
}

.productaddedpopup{
	left: auto;
	right: 0px;
}

.productaddedpopup::before{
	left: calc(81% - 10px);
}

.onthego_block{
	min-height:265px;
}
.booz_desc{
	padding:0px 5px;
}
.brands{
	margin-bottom:20px;
}
.innerpagemargintop p{
	text-align:justify;
}
.addressbook-btmpannelcover .addressbokk-trvaichacovr:nth-child(3n){
	margin-right:17px;
}
.addressbokk-trvaichacovr.ng-scope:nth-of-type(2n+2) {
    margin-right: 0 !important;
}
.addressbokk-addnewadresscovr{
	min-height:140px;
}
.addnewadresstext{
	min-height:initial;
}
#cart-summary-icon{
	display:block !important;
}
.checkoutstep5right-middle-title{
	width:73%;
}
.footer1 {
    padding-top: 40px;
}
.hidden-scroll{
	overflow:hidden;
}
.ro_desc{
	width:82%;
	float:left;
	display: inline-block;
    float: none;
	 vertical-align: middle;
	 font-size:14px;
}
.ro_pic {
    display: inline-block;
    float: left;
    vertical-align: middle;
    width: 60px;
}
.comboproductdetailitems{
	background: #ffffff none repeat scroll 0 0;
    margin: -1px 0 10px;
    padding: 10px 0 0 !important;
    width: 25%;
    border: 1px solid #d6dce0;
}
.productdetailtitle{
	background: #fff;
	border: 1px solid #d6dce0;
	padding: 15px;
}
.checkoutmiddletable table td{
	padding-left:8px !important;
	padding-right:8px !important;
}
.thumbnail.cocktail-products-div{
	width:95%;
}
.chekoutstep5-table .col-md-3.ng-scope{
	width:25%;
}
md-checkbox.salecheck .md-label, md-checkbox.salelist .md-label{
	font-size:13px !important; 
}
.salediv{
	text-align:left !important;
}
.checkoutrighbuttonadd{
	width:40%;
}
.checkoutrighbuttoncancel{
	width:40%;
}
/*md-dialog.md-dialog-fullscreen{
	min-height:inherit;
}*/
/*md-dialog.md-dialog-fullscreen{
	min-width:70%;
}*/
.no-image.prod_pic{
	background-size:auto;
}
.productdetailbrudcumcover iframe{
	width:100%;
	
}
.prddetailchilledcover{
	margin-top:60px;
}
.has-sale-div .prddetailtitle{
	width:57%;
}
 .has-sale-div .prddetailsubexttitle{
	width:50%;
}

.adresfloortxtcvr{
	width: 45%;
}
.adressunit{
	width: 44%;
}
.alcohal_adress2fotter{
	width:72%;
}
.product-listcover2{
	padding: 0px;

}
.product-listcover2 .salediv,.combo_sliderProduct .salediv,.prod-in-slider .owl-item .salediv{
	display: none;

}
.product-listcover2 .cartbtn,.combo_sliderProduct .cartbtn,.prod-in-slider .owl-item .cartbtn{
	width: 100%;
	border-radius: 0px;
}
.product-listcover2 .prod_addtocart,.combo_sliderProduct .prod_addtocart,.prod-in-slider .owl-item .prod_addtocart{
	margin-bottom: 0px !important;
}
.innerpagemargintop .combo_slider .md-padding{
	padding: 0px !important;
}
.product-listcover2 .prod_pic,.combo_sliderProduct .prod_pic,.prod-in-slider .owl-item .prod_pic{
	padding: 0px 10px;
}
.combo_sliderProduct .prod_pic img,.prod-in-slider .owl-item .prod_pic img{
	max-width: 100%;
}
.product-listcover2 a.core-block,.combo_sliderProduct a.core-block,.prod-in-slider .owl-item a.core-block{
	text-align: center;
	padding:0px 10px 15px;
}
.combo_sliderProduct .prod_desc,.combo_sliderProduct .prod_price,.combo_sliderProduct .prod_discout_price,
.owl-item .prod_desc,.owl-item .prod_price,.owl-item .prod_discout_price{
	text-align: center;
}
.combo_sliderProduct .label.label-warning.ng-binding.ng-scope,.prod-in-slider .owl-item .label.label-warning.ng-binding.ng-scope{
	display: inline-block;
	float: none;
}
.combo_sliderProduct .prod_desc.ng-binding,.prod-in-slider .owl-item .prod_desc.ng-binding{
	  height: 34px;/*margin-bottom: 0;*/
}
.combo_sliderProduct{
	background:none;
	padding-top: 0px;
	border: none;
}
.combo_sliderProduct md-tabs-wrapper,.prod-in-slider .owl-item md-tabs-wrapper{
	background: #fff !important;
	border-radius: 5px 5px 0 0;
}
.combo_sliderProduct md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar{
	background: #aa00ff !important

}
.combo_sliderProduct .prod_discout_price,.prod-in-slider .owl-item .prod_discout_price{
	font-size: 20px;
}
.combo_sliderProduct .addmore,.prod-in-slider .owl-item .addmore{
	padding: 0px 8px;

}
.comboproductitems .prod_desc,.prod-in-slider .owl-item .prod_desc{
	font-size: 14px;
}
.combo_sliderProduct .prod_pic img,.prod-in-slider .owl-item .prod_pic img{
	max-width: 100%;
}
.combo_sliderProduct .prod_chilled,.prod-in-slider .owl-item .prod_chilled{
	text-align: center;
}
.combo_sliderProduct .prod_chilled span,.prod-in-slider .owl-item .prod_chilled span{
	float: none;
	text-transform: uppercase;
}
.combo_sliderProduct .prod_chilled_icon,.prod-in-slider .owl-item .prod_chilled_icon{
	display: inline-block;
	float: none !important;
	vertical-align: middle;
}
.combo_sliderProduct .productsoldbt,.combo_sliderProduct .productsoldbt:hover,.prod-in-slider .owl-item .productsoldbt,.prod-in-slider .owl-item .productsoldbt:hover{
	border-radius: 0px;
	width: 100%;
}
.combo_sliderProduct #toastBounds,.prod-in-slider .owl-item #toastBounds{
	margin-bottom: 0px !important;
	width: 100%;
}
.combo_sliderProduct .md-tab,.prod-in-slider .owl-item .md-tab{
	font-size: 20px;

}
.combo_sliderProduct .productsoldbt img,.prod-in-slider .owl-item .productsoldbt img{
	float: none;
	margin-right: 5px;
}
.combo_sliderProduct .addcart span,.prod-in-slider .owl-item .addcart span{
	height: 24px;
    margin: 0 5px;
    vertical-align: middle;
    width: 19px;
    float: none;
}
.combo_sliderProduct .md-nav-bar,.prod-in-slider .owl-item .md-nav-bar{
	background: #fff !important;

}
.comboproductitems,.comboproductitems:hover {
    margin: 0px 0 10px !important;
    padding: 10px 0px 0px !important;
    width: 25%;
	background: #fff;
	 border: 1px solid #d6dce0;
    box-shadow: none !important;
    transition: all 0.5s ease 0s;
}
.prod-in-slider .owl-item .proHoverEffect{
	margin: 0px 0 10px !important;
    padding: 10px 0px 0px !important;
    width: 100%;
	background: #fff;
	 border: 1px solid #d6dce0;
    box-shadow: none !important;
    transition: all 0.5s ease 0s;
    width: 100%;

}
.prod-in-slider {
	padding: 10px !important;
}
.desktop-showblock{
	display: none;
}
.responsive-blokimg{
	display: block;
}
.wishlist-main-outer{
	display: none;
}
.header {
    background-size: auto 760px;
}
.combo_sliderProduct .addmorehere,.prod-in-slider .owl-item .addmorehere{
	display: none;
}
.combo_sliderProduct .addmore-count,.prod-in-slider .owl-item .addmore-count{
	font-size: 20px;
	line-height: 30px;
}
.combo_sliderProduct .addmore .bootstrap-touchspin-down,.combo_sliderProduct .addmore .bootstrap-touchspin-up,
.prod-in-slider .owl-item .addmore .bootstrap-touchspin-down,.prod-in-slider .owl-item .addmore .bootstrap-touchspin-up{
	font-size: 20px;
}
.combo_sliderProduct .addmore .bootstrap-touchspin,.prod-in-slider .owl-item .addmore .bootstrap-touchspin{
	width: 100%;
	margin-left: 0px;
	height: 100%;
}
.combo_sliderProduct .md-button,.prod-in-slider .owl-item .md-button{
	font-size: 17px;
	text-transform: none;
}
.combo_sliderProduct .addmore,.prod-in-slider .owl-item .addmore{
    padding: 0 24px;
    line-height: inherit;
}
.combo_sliderProduct .cartbtn,.prod-in-slider .owl-item .cartbtn{
	font-size: 13px;
}
.combo_sliderProduct .saletags-list,.prod-in-slider .owl-item .saletags-list{
	font-size: 12px;
}
.productsoldbt, .productsoldbt:hover{
	font-size: 13px;
}
.combo_sliderProduct .productbeerfiltertopcover,.prod-in-slider .owl-item .productbeerfiltertopcove{
	background: #fff;
	padding-top: 15px;
}
.combo_sliderProduct .tabbable .fullwidth,.prod-in-slider .owl-item .tabbable .fullwidth{
	background: #fff;
	margin-top: 0px;
}
.congratulationpopuptext{
	
	padding-right: 0px;	
}
/* .notify {
	box-shadow: 1px 1px 4px 4px #cccccc;
} */
md-toast.notify div.md-toast-content{
	float: right !important;
}
.product-bread-crumb{
	text-align: left;
}
.comboproductitems .salediv{
	display: none;
}
.comboproductitems .prod_addtocart{
	margin-bottom: 0px;
}
.comboproductitems .checkoutproductbutton{
	border-radius: 0px;
}
.comboproductitems .prod_pic img{
	max-width: 100%;
}
.comboproductitems .prod_desc,.comboproductitems .prod_price,.comboproductitems .prod_discout_price{
	text-align: center;
}
.comboproductitems a.core-block{
	padding:0px 10px 15px;
	text-align: center;

}
.owl-item .comboproductitems,.owl-item .comboproductitems:hover{
	width: 100% !important;
}

.closemenu{
	display: block;

}
.item-btmtitle.ng-binding {
	height: 45px;
	overflow: hidden;
}
.notlinkmenu{
	pointer-events: none;

}
.dont-miss-footerhide{
	display: none;
}
.dont-miss-res-show{
	display: block;
}

.logos-inner-cover > a{
	display: inline-block;
}
.navbar-fixed-top .logos-inner-cover{
	width: 170px !important;
	display: inherit !important;
	margin-top: 5px;
}
.navbar-fixed-top .logos-inner-cover.hide{
	display: none !important;
}
.comboproductitems, .comboproductitems{
	border-right: none !important;
}
.comboproductitems:nth-of-type(4n+4) , .comboproductitems:nth-of-type(4n+4):hover{
	border-right: 1px solid #d6dce0 !important;
}
.productcomboul{
	border-bottom: none !important;
}
.prod_chilled{
	min-width: 90px;
}


.modify .dropdown-menu{
	min-height: auto;
}




.announcement-container span {
    max-width: 76%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    padding: 0 10px;
}

.announcement-container img {
    max-height: 42px;
}

.announcement-container {
    position: static;
    top: 0;
    height: auto;
}


.announcement-enable-wrapper .announcement-bar a {
    /*display: table-cell;*/
    display: inline-block;
    text-align: center;
    width: 100%;
    float: none;
    /*height: 60px;*/
    vertical-align: middle;
}


.outer {
    display: inline-block;
}

.announcement-enable-wrapper .announcement-bar{
	padding: 0;
}
.checkoutcongratulationouter {
    border-bottom: medium none;
}
.cartstepone-cover .owl-carousel .owl-item .comboproductitems:nth-of-type(2n+1), .cartstepone-cover .owl-carousel .owl-item .comboproductitems:nth-of-type(2n+1):hover {
    border-right: 1px solid #d6dce0 !important;
}
.cartstepone-cover .owl-carousel .owl-item{
	width: 175px !important
}
.newfeature-text{
	background: #fff;
	padding-top: 10px;
}
}

@media only screen and (max-width : 767px) {
  .col-md-2.homecallus_cover {
	  display: none;
  }
  .logolarge {
	  left: inherit;
	  width: 125px;
  }
  .navbar-fixed-top .signuplogin ul.menuulpadding > li:nth-child(2) {
	  display: none;
  }
  .navbar-fixed-top .signuplogin ul.menuulpadding > li:nth-child(1) {
	  display: none;
  }
 
  .navbar-fixed-top .signuplogin ul.menuulpadding > li:nth-child(4) {
	  display: table;
  }
  .side-menu-responsive {
	  left: 0;
	  position: absolute;
	  top: 0;
	  z-index: 9999;
	  display: none
  }
  .occassion_sale {
	  font-size: 70px;
	  float: left;
	  line-height: normal;
	  padding-bottom: 25px;
	  padding-top: 40px;
  }
  .tabtitle {
	  width: 100%
  }
  #bs-example-navbar-collapse-1 {
	  display: none
  }
  .item.ng-scope {
	  display: inline-block;
	  float: none;
	  margin: 0;
	  text-align: center;
	  width: 100%;
  }
  .item.ng-scope div {
	  /* display: inline-block;
	  float: none;
	  text-align: center; */
  }

.addmore .bootstrap-touchspin{
	float: left !important;
	display: table !important;
}

  .registernowbtn.ng-scope {
	  margin: 20px 0;
  }
  .prod_pic {
	  margin-bottom: 15px;
  }
  .prod_desc.ng-binding {
	  overflow: hidden;
	  margin-bottom: 0
  }
  .prod_addtocart {
	  margin-bottom: 5px;
  }
  .prod_addtocart {
	  margin-bottom: 5px;
  }
  .offers {
	  width: 100%
  }
  .offrates {
	  width: 49%;
	  margin-bottom: 10px;
  }
  .offrates_txt {
	  font-size: 48px
  }
  .happy_hours {
	  width: 49%
  }
  .fullwidth.ng-scope.header {
	  margin-bottom: 0;
  }
  .single_slider.ng-scope {
	  width: 100%;
	  padding: 20px 0px;
	  background-size: cover
  }
  .slido_right {
	  float: right;
	  font-size: 15px;
	  font-weight: 500;
	  margin-top: 10px;
	  padding: 0 20px;
	  width: 100%;
  }
  .single_slider.ng-scope .owl-carousel .owl-item {
	  width: 100%;
  }
  .slido_left {
	  margin-top: 0;
	  width: 100%;
  }
  .slido_right {
	  font-size: 15px;
	  font-weight: 500;
	  margin-top: 10px;
	  padding: 0 20px;
	  width: 100%;
  }
  .orderbooz {
	  margin-top: 20px
  }
  .socio_btns {
	  margin-top: 30px
  }
  .fullwidth.social_block {
	  padding: 20px 0;
  }
  .socilblok_twtr {
	  margin-left: 0;
	  text-align: center;
	  width: 27%;
	  margin: 5px 0
  }
  .socilblok_fb {
	  margin-left: 0;
	  text-align: center;
	  width: 24%;
	  margin-top:5px;
  }
  .socilblok_folowtxt {
	  margin-left: 0;
	  text-align: center;
	  width: 49%;
	  
  }
  .socilblok_twtr img {
	  display: inline-block;
	  float: none;
  }
  .socilblok_twtr > a > span {
	  display: inline-block;
	  float: none;
	  padding-left: 0;
  }
  .socilblok_fb img {
	  display: inline-block;
	  float: none;
  }
  .socilblok_fb > a > span {
	  display: inline-block;
	  float: none;
	  padding-left: 0;
  }
  .footer1_pannel1cvr {
	  width: 100%
  }
  .footer1_sociallink {
	  margin: 20px 0;
  }
  .footer1_privcytext {
	  width: 42%
  }
  .footer1_pannel2cvr {
	  width: 30%
  }
  .footer1_pannel3cvr {
	  width: 30%
  }
  .footer1_pannel4cvr {
	  width: 37%
  }
  .footer2_btmcvr {
	  margin-top: 20px
  }
  
 
  .leftphone {
	  max-width: 100%;
	  display:inline-block;
  }
  .col-md-4.phone-img-responsive {
    text-align: center;
}
  .onthego_block {
	  background-size: cover
  }
  .dropdown-menu a:focus .nav.sidebar-nav li a {
	  background: none !important
  }
  .dropdown-menu a:hover .nav.sidebar-nav li a {
	  background: none !important
  }
  .dropdown-menu a:active .nav.sidebar-nav li a {
	  background: none !important
  }
  .brands .owl-carousel .owl-item {
	  display: table-cell;
	  float: none;
	  max-width: 100%;
	  padding-bottom: 10px;
	  text-align: center;
	  vertical-align: middle;
  }
  .single_slider.ng-scope {
	  margin-bottom: 30px;
  }
  .searchtop {
	  right: 18.5%
  }
  .searchtop100 {
	  right: 15px;
	  width: 88%;
  }
  .onthego {
	  font-size: 100px
  }
  .footer2_madetxt {
	  font-size: 40px;
	  margin-bottom: 0;
  }
  
  .searchtop.searchtop100 {
	  margin-top: 0px;
	  right: 2%;
  }
  .ancontainer {
	  z-index: 9999
  }
  .searchopen .hamburger {
	  display: none
  }
  .searchtop.searchtop100 {
	  float: left;
	  margin-top: 0;
	  right: inherit;
	  width: 100%;
  }
  /*.currentorderpopup {
	  left: -238px
  }
  .currentorderpopup::before {
	  left: 85%
  }*/
  .modal-dialog.alcohal_registermain {
	  text-align: center;
	  width: 93%;
	  float: none;
	  margin: 10px auto;
  }
  .alcohal_regisemailcvr {
	  width: 100%
  }
  .gift-packagecoverdiv {
	  width: 100%;
	  margin: 0;
  }
  .modal-dialog.alcohal_addadressmain {
	  margin-left: 0;
	  margin-right: 0;
	  width: 100%;
	  margin-top: 0;
  }
  /*md-dialog {
	  max-height: 90%;
	  max-width: 90%;
	  overflow-y: auto;
	  overflow-x: hidden;
	  opacity: 1;
  }*/
  .alcohal_adress2fotter {
	  width: 68%
  }
  .modal-body.alcohaladdadremapbody {
	  padding: 10px;
  }
  .modal-body.gift-packgng-bodydiv {
	  padding-left: 0;
	  padding-right: 0;
	  text-align: center;
  }
  .gift-item-cover {
	  display: inline-block;
	  float: none;
	  margin: 0 5px 15px auto;
  }
  .gift-packagecoverdiv {
	  background-size: initial
  }
  .gift-item-cover:last-child {
	  margin: 0 5px 15px auto;
  }
  .md-toolbar-tools.alcohal_addadress_header {
	  float: left;
	  height: auto;
	  margin-bottom: 0;
	  max-height: none;
  }
  .pushy.pushy-left {
	  display: block
  }
  #container {
	  display: block
  }
  .dropdown.cart-icon-set span {
	  display: table-cell
  }
  .hamburger.is-closed {
	  margin-left: 4px;
  }
  .img-responsive.logolarge {
	  top: 0
  }
  .navbar-fixed-top.navbar-shrink .text-center .logosmall {
	  left: inherit
  }
  .rgrhtdiv {
	  width: 100% !important;
	  padding: 21px 20px 33px 20px;
  }
  .laeftdivmain {
	  width: 100%
  }
  .logoss {
	  min-width: 100%;
	  position: absolute
  }
  .text-center .logosmall {
	  position: relative;
	  left: inherit;
  }
  .logolarge {
	  position: relative
  }
  .breadcrumdiv .breadcrumb {
	  margin-bottom: 4px
  }
  .titlecreidt {
	  margin-bottom: 0
  }
  .balancedetailsdiv2 {
	  width: 100%;
	  margin-bottom: 20px;
  }
  .profile_form {
	  width: 100%
  }
  .registeremailcvr {
	  margin-bottom: 15px
  }
  .balancetexxt {
	  width: 100%;
	  text-align:justify;
  }
  .-key.cursor-pointer.ng-binding {
	  width: 100%;
	  float: left
  }
  .order-history .-status {
	  border: medium none;
	  min-height: 50px;
	  padding-bottom: 0;
	  padding-left: 0;
  }
  .history-title-padding {
	 
  }
  .titlecreidt {
    margin-bottom: 10px;
}
  .balancetexxt2 {
	  margin-bottom: 0
  }
  .change_form {
	  width: 100%
  }
  .change_form .registeremailcvr.paswrd-width-set5 {
	  float: left;
	  margin-right: 10px;
	 
  }
  .change_form .registeremailcvr.paswrd-width-set5:nth-child(2) {
	  margin-right: 0
  }
  .wishlist-text.ng-scope {
	  margin-bottom: 10px;
	  width: 100%;
  }
  .addressbokk-trvaichacovr {
	  margin: 10px 10px 10px 0 ;
	  min-height: 145px;
	  padding: 20px 10px;
	  width: 48.5%;
  }
  .addressbook-btmpannelcover .addressbokk-trvaichacovr{
	  margin-right: 10px !important;
	 }
	 .addressbook-btmpannelcover .addressbokk-trvaichacovr:nth-child(3n) {
    margin-right: 10px;
}
  .addressbokk-trvaichacovr:nth-child(2) {
	  margin-right: 0
  }
  
  .addressbokk-addnewadresscovr {
  }
  .addressbokk-addnewadresscovr {
	  margin: 10px 10px 10px 0;
	  min-height: 145px;
	  padding: 20px 15px;
	  width: 48.5%;
	  margin-right: 0
  }
  .addressbook-btmpannelcover {
	  margin-bottom: 10px
  }
  .checkouter-td-right-text {
	  
	  margin-right: 0px;
	  width: 56%;
	  display: inline-block;
	  margin-left:10px;
  }
  .checkoutmiddletable .orhistry2_td2procdtcvr > span{
  	width: 50px;
  	height: 50px;
  }
  .checkoutmiddletable .orhistry2_td2proctext2 {
	  float: left
  }
  .checkoutmiddletable .orhistry2_td2procdtcvr > span {
	  margin-top: 5px
  }
  .wishlist-tblestock.ng-scope {
	  float: left;
	  width: 100%;
  }
  .orhistry2_td2procdtcvr > span {
	  margin-right: 0;
  }
  .table.wislistborbtm.ng-scope {
	  border-bottom: medium none;
	  margin-bottom: 0 !important;
  }
  .orhistry2_td2proctext1 {
	  float: left;
	  width: 100%;
  }
  .table-responsive {
	  border: medium none;
  }
  .wishlist-tbledatecover {
	  display: inline-block;
	  float: right;
	  padding-left: 50px;
  }
  .checkboxtotaldiv-top {
	  padding: 15px 0px 15px 0;
  }
  .checkboxtotaldiv-title {
	  width: 75%
  }
  .checkboxtotaldiv-middle {
	  padding: 15px 20px 15px 0;
  }
  .checkboxtotaldiv-buttom {
	  padding: 15px 20px 15px 0;
  }
  .checkout-product .col-md-3.col-sm-3.col-xs-12.m-bottom-20 {
	  width: 50%
  }
  .step_grid .img-responsive {
	  padding: 0 10px;
	  width: 100%;
  }
  .cover_div_addsboxs {
	  width: 100%
  }
  .div_addsboxs.ng-scope {
	  margin: 0 0 20px;
	  width: 48.5%;
	  margin-right: 10px;
	  padding: 20px 10px;
  }
  .div_addsboxs.ng-scope:nth-of-type(2n) {
	  margin-right: 0;
  }
  .addressbokk-trvaichacovr.ng-scope:nth-of-type(2n) {
	  margin-right: 0;
  }
  .lft_textadd_div {
	  position: relative
  }
  .edit_del_icondiv {
	  float: left;
	  position: absolute;
	  width: 100%;
  }
  .coverboxsegment_div {
	  margin-bottom: 10px;
  }
  .alcohlchekboxdiv12 {
	  width: 100%
  }
  .checkouterlastdiv.ng-scope {
	  padding-top: 0;
  }
  .alcohlinputext2 {
	  width: 80%
  }
  .alcohlinputextarea12 {
	  width: 100%
  }
  .cover_creadetcard_cashdiv {
	  margin-top: 30px
  }
  .prod_chilled {
	  margin-left: 0;
	  margin-right: 0;
	  text-align: center;
  }
  .checkoutstep5-mid-right {
	  float: left;
	  margin-top: 20px;
	  width: 100%;
  }
  .checkoutstep5-mid-left {
	  width: 100%
  }
  .step1 > span {
	  display: none
  }
  .step2 > span {
	  display: none
  }
  .step3 > span {
	  display: none
  }
  .step4 > span {
	  display: none
  }
  .step5 > span {
	  display: none
  }
  .steps_notify .step_grid a span {
	  display: none;
	  width: 100%;
	  left: 52px;
	  top: 64px
  }
  .step1.currentstep > span {
	  display: block!important
  }
  .step2.currentstep > span {
	  display: block !important
  }
  .step3.currentstep > span {
	  display: block!important
  }
  .step4.currentstep > span {
	  display: block!important
  }
  .step5.currentstep > span {
	  display: block !important
  }
  button.checkoutergitpackage {
	  float: left;
	  margin-bottom: 10px;
	  margin-top: 10px;
  }
  button.checkoutergitpackage {
	  width: 276px
  }
  .steps_notify .step_grid a {
	  width: 100%
  }
  .steps_notify .step_grid a span {
	  display: none;
	  font-size: 21px;
	  left: 0;
	  top: 67px;
	  width: 100%;
	  color: #666666
  }
  .steps_notify .step_grid a {
	  left: 0
  }
  .right-smoke-main .fullwidth > table {
	  display: inline-block;
	  width: 100%;
  }
  .right-smoke-main .fullwidth tbody {
	  display: inline-block;
	  width: 100%;
  }
  .right-smoke-main .fullwidth tr {
	  float: left;
	  margin: 0 auto;
	  text-align: center;
	  width: 100%;
  }
  .right-smoke-main .fullwidth td {
	  display: inline-block;
  }
  .right-smoke-main.ng-scope {
	  float: left;
	  margin-bottom: 20px;
	  width: 100%;
  }
  .chckoutstep6main-cover {
	  width: 100%;
	  float: left;
	  text-align: center;
  }
  .checkout-step6-innercover {
	  width: 100%;
	  float: left
  }
  .checkout-step6-innercover {
	  width: 100%;
  }
  .order-numberinnercover.ng-binding {
	  padding: 0;
	  width: 100%;
	  font-size: 18px
  }
  .paymentdetail-fixwidth {
	  width: 100%;
  }
  .ahare-purchase-btmtxt {
	  padding: 0 5px;
	  width: 100%;
  }
  .ordernno-divcenterset {
	  width: 100%;
	  padding:0px 15px;
  }
  md-datepicker {
	  margin-right: 0
  }
  .md-button.md-icon-button {
	  margin-right: 0;
	  margin-left: 0;
  }
  .footer-container-borderset {
	  width: 94%;
	  margin-top:20px;
  }
  .alcohal_reapeatorederbodycvr {
	  width: 100%
  }
  .repaet-layout-height {
	  height: auto !important;
	  border-top: medium none !important;
  }
  .alcohal_reapeatorederbtnsocvercvr {
	  margin: 0 auto;
	  padding: 0;
	  width: 70%;
	  padding-bottom: 20px
  }
  .md-dialog-content.alcohaladdadrebody.alcohal_reapeatorederbody {
	  padding-left: 15px;
	  padding-right: 15px;
  }
  .alcohal_reapeatorederbodytitle {
	  font-size: 28px
  }
  .alcohal_orderhistorytxtcvr {
	  width: 100%
  }
  .alcohal_hisryorderboxcvr1 {
	  width: 46%;
  }
  .alcohal_hisryorderboxcvr1:nth-child(2n+2) {
	  margin-right: 0;
  }
  .alcohal_hisryorderboxcvr1:nth-child(3n+3) {
	  margin-right: 19px;
  }
  .alcohalfooter_shopordershopbtn {
	  float: none;
	  display: inline-block;
  }
  .modal-footer.alcohal_orderhistoryfotter {
	  padding-right: 0;
	  width: 100%;
	  text-align: center;
	  padding-left: 0;
  }
  .alcohal_orderhistory2fotter {
	  padding-bottom: 20px;
	  width: 56%;
  }
  .productdetailcovermainbgimg.ng-scope {
	  padding-left: 10px;
	  padding-right: 10px;
  }
  .owldownpartcover img {
	  margin-left: 0
  }
  ng-map {
	  float: left
  }
  .productinner_leftslide.left-main-outerdiv {
	  width: 100%;
	  padding:0px 15px;
  }
  .productslide_normaltext{
	  white-space:normal;
	 }
  /*.left-main-outerdiv .productslide_heading.ng-binding {
	  font-size: 17px;
	  height: 47px;
	  line-height: 24px;
	  overflow: hidden;
	  padding: 0 5px;
  }*/
  .dropdown.productdropdownbtn {
	  margin-right: 0;
  }
  .dropdown.productdropdownbtn span1 {
	  float: left;
	  position: relative;
  }
 /* .productslide_normaltext.ng-binding {
	  height: 33px;
	  overflow: hidden;
	  padding: 0 5px;
  }*/
  .owl-wraaper-margin .owl-wrapper {
	  margin-bottom: 33px;
  }
  .comboproductitems {
	  width: 48%
  }
 /* .comboproductitems:nth-of-type(2n+2) {
 	  float:right;
  } 
  .productbeerfiltertopcover .row div {
	  width: 25%
  }*/
  .productdropdownbtn .dropdown-menu {
	  left: 0
  }
  .alcohal_addadress_headertitle {
	  font-size: 18px
  }
  .alcohalorderhisttitle {
	  font-size: 22px
  }
 /* .alcohal_addadress_headercurcle {
	  margin-top: -4px
  }*/
 
  
  .single_slider_product .owl-controls {
	  width: 100%;
	  text-align: center;
	  display: inline-block
  }
  .part-content-btm-desc {
	  padding-left: 15px;
	  padding-right: 15px;
  }
  .btmcontn-set a {
	  width: 25%;
	  font-size: 15px;
	  margin: -2px 0;
  }
  .package-desc {
	  width: 71%
  }
  .customize-text-cover > span {
	  padding-left: 4px;
  }
  .title-propertset {
	  font-size: 21px
  }
  .media-body.party-detail-contentcover {
	  padding-left: 0;
  }
  .party-detail-heading {
	  font-size: 24px
  }
  .party-detail-btmtitl4 > h4 {
	  font-size: 16px;
  }
  .ttile-divcover.party-deatil-pading5.ng-scope {
	  padding-left: 4px;
  }
  .left-party-content {
	  width:100%
  }
  .media-body.cockatil-bodycover {
	  padding-left: 0;
	  width: 48%;
  }
  .media-left.cocktail-leftimgdiv {
	  width: 50%;
	  height: 100%
  }
  .moredetail-cocktailtext > a {
	  font-size: 15px;
	  margin: 2px 0;
	  width: 25%;
  }
  .product-desccocktail.ng-binding {
	  width: 100%;
	  max-width:100%;
	  font-size:14px;
  }
  .cocktail-amntdivcover {
	  float: left;
	  text-align: center;
	  width: auto;
  }
  .saving-cocktail-cover span {
	  width: auto
  }
  .saving-titie-amnt {
	  width: auto
  }
  .title-propertset {
	  margin-top: 15px;
  }
  .cocktail-detail-width {
	  width: 42%;
	  float: left
  }
  .add-cart-cover {
	  width: 100%;
  }
  .package-amtcover {
	  float: left;
	  width: 70%;
  }
  .saving-amount-cover {
	  width: 30%;
	  padding-left: 0
  }
  .btmcontn-set a {
	  font-size: 13px;
	  margin: 0px 0;
	  width: 29%;
  }
  .amntrupee-clsdetail {
	  font-size: 23px !important
  }
  .title-propertset.ng-binding {
	  font-size: 21px;
	  margin-top: 0;
  }
  .moredetail-cocktailtext > a {
	  font-size: 14px;
	  margin: 2px 0;
	  text-align:left;
	  width: 100%;
  }
  .party-detail-btm-width {
	  width: 33.33%;
	  float: left;
  }
  .edit_del_icondiv {
	  display: block;
  }
  .navbar-fixed-top.navbar-shrink .hamburger {
	  top: 23px;
  }
  .yelo_btn {
	  padding: 6px 15px;
  }
  .col-md-10.top-logo-sideacnt-cover.text-center {
	  width: 100%
  }
  .hamburger {
	  left: 15px;
	  top: 40px;
  }
  .img-responsive.logolarge {
	  position: relative
  }
  .navbar-fixed-top .logos-inner-cover {
	  min-width: auto;
	
  }
  .img-responsive.logosmall {
	  left: 0;
	  position: relative;
	  top: 0;
  }
  .innerpagemargintop {
	  margin-top: 110px;
  }
  .gift-card-rightcover .media-heading.party-detail-heading.ng-binding {
	  padding-top: 10px;
  }
  .gift-lstin-mainouter .prod_desc {
	  margin-bottom: 14px
  }
  .tabsright {
	  width: 83%
  }
  .fullwidth.top-tabmainouter > .right-crousel {
	  float: left;
	  margin-bottom: 20px !important;
	  padding-left: 5px;
	  padding-right: 5px;
	  width: 100%;
	  margin-right: 0
  }
  .tab-content.fullwidth .owl-item {
	  width: inherit
  }
  .top-tabmainouter .owl-item {
	  width: inherit;
	  position: relative;
	  top: 8px;
  }
  .slido_left {
	  position: relative;
  }
  .top-tabmainouter .owl-next {
	  right: 0
  }
  .top-tabmainouter .owl-prev {
	  left: 0
  }
  .slido_right {
	  min-height: inherit
  }
  .slido_right.ng-binding {
	  padding: 10px 7px
  }
  .text-capitalize {
	  font-size: 14px
  }
  .btm10.ng-scope.searchopen .menu-icon {
	  display: none
  }
  .searchtop.searchtop100 {
	  top: 39px
  }
  .comboproductitems .add-wishlist-icondiv {
	  display: block
  }
  .comboproductitems .bulk-dis-icon {
	  display: block
  }
  .nav.nav-tabs.tabsright {
	  padding-bottom: 20px
  }
  .add-icon-main {
	  position: relative
  }


.innerpagemargintop .nav.navbar-nav .dropdown-menu{
	background-image: none !important;
	background-color: #444444;
	box-shadow: none;
}

.dropdown-menu.ng-scope.animate {
    padding: 10px 0px!important;
	box-shadow:none
}

/*.modify .dropdown-menu li a{
	padding: 8px 0px !important;
}
*/


.navbar-default .navbar-nav li a{
	font-size: 17px;
	padding: 10px 0;
}

.navbar-default .navbar-nav .dropdown-menu a{
	padding: 15px !important;
}


.footer1{
	padding-top:20px;
}
.footer2_madetxt{
	margin-top:10px;
}
.footer2_copytxt{
	margin-top: 15px;
	margin-bottom: 30px;
}
.productinner_rightslide{
	height:240px;
	position:relative;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 20px;
	width:100%;
}
.featured_product{
	position: absolute;
	top: 0px;
}
.checkoutcongratulationouter{
	border-bottom: none;
}
.productinner_rightslide img{
	position:absolute;
	top:0px;
	bottom:0px;
	left:0px;
	right:0px;
	margin:auto;
}
.innerpagemargintop p{
	text-align:justify;
}
.innerpagemargintop h3 {
    font-size:18px;
}
.addressbook-btmpannelcover .addressbokk-trvaichacovr:nth-child(3n){
	margin-right:17px;
}
.addressbokk-trvaichacovr.ng-scope:nth-of-type(2n+2) {
    margin-right: 0 !important;
}
.addresseditdltpic > a{
	padding-left:5px;
}
.textboldtit{
	font-size:14px;
}
.textnormalit{
font-size:18px;
}
.sale-tag-txt.-cart{
	float:left;
}
.cart-table .strike-txt{
	float:left;
}
.checkout_title,.uralset-textdiv{
	font-size:30px;
}
.keepshoping-textcover{
	font-size:24px;
}
#cart-summary-icon{
	display:block !important;
}
.currentorderpopup::before{
	left:inherit;
	right:35px;
}
.emptycartbox {
    left: inherit;
    right: 0;
}
.currentorderpopup{
	left:inherit;
	right:0px;
}
.dropdown.cart-icon-set span {
    display: inline-block !important;
}
.checkoutstep5right-bottom-title{
	width:72%;
}
.cocktail-amntdivcover > span{
	float:left
}
.happy_hours_txt{
	font-size:100px;
}
.innerpagemargintop .combo_slider{
	margin-bottom:0px;
}
.slido_right{
	height:inherit;
}
.proHoverEffect,.col-xs-12.col-sm-3.col-md-3.comboproductitems {
    box-shadow: none !important;
    transition: all 0.5s ease 0s;
	border:1px solid #d6dce0;
} 
.proHoverEffect .add-wishlist-icondiv, .comboproductitems .add-wishlist-icondiv {
    display: block !important;
}

.proHoverEffect md-checkbox.salecheck, .comboproductitems md-checkbox.salecheck {
    opacity: 1;
}
.wishlist-main-outer {
	display: none !important;
    float: left !important;
    left: 0;
    text-align: left !important;
	position:static;
}
.add-bulk-iner-widthset {
    text-align: left !important;
}
.add-bulk-wishlist-icon {
    float: left !important;
    text-align: left !important;
}
.hidden-scroll{
	
	overflow:hidden !important;
	-webkit-overflow-scrolling: touch;
}
.navbar-default .navbar-nav li .caret{
	background: rgba(0, 0, 0, 0) url("../images/caret_r-white.png") no-repeat scroll center center;
	
}
#toastBounds{
	margin-bottom:5px;
}
.salediv{
	float:left !important; 
}
md-checkbox.salecheck .md-label, md-checkbox.salelist .md-label{
	font-size:13px !important;
	text-align:left !important;
}

.ro_desc{
	width:75%;
	float:left;
	display: inline-block;
    float: none;
	 vertical-align: middle;
	 font-size:14px;
	 white-space: normal;
}
.ro_pic {
    display: inline-block;
    float: left;
    vertical-align: middle;
    width: 60px;
}
.footer1_title{
	margin-bottom:10px;
}
.searchtop.searchtop100 .search_icon{
	padding:0px 0px !important;
	left:0px !important;
	width:25px;
	background-position: -315px 0;	
	
}
.comboproductdetailitems{
	background: #ffffff none repeat scroll 0 0;
    margin: -1px 0 10px;
    padding: 10px 0 0 !important;
    width: 33.33%;
}
.has-sale-div .prddetailtitle, .has-sale-div .prddetailsubexttitle, .has-sale-div .prddetailnormaltext, .has-sale-div .prddetailnormaltext{
	width:100%;
}
.tag-purple-new-pos{
	right:4px;
	float:right;
}
.repeatbtn{
	float:left;
	margin-left:10px;
}
/*.md-dialog-container, md-dialog {
	z-index:99999; 
}*/
/*.navbar-fixed-top.navbar-shrink .logos-inner-cover{
	display:unset;
}*/
md-checkbox.salecheck .md-label, md-checkbox.salelist .md-label{
	min-width:220px;
}

.package-desc{
	font-size:16px;
}
.alcohal_reapeatorederbtnsocvercvr{
	text-align:center;
}
.alcohalfooter_repeatorderbtn{
	padding:10px 20px;
	float:none;
	margin-right:0px !important; 
}
.thumbnail.cocktail-products-div{
	width:90%;
	
}
.prddetailnormaltext{
	width:100%;
}
.checkoutmiddlevlauediv{
	width:43%;
}
.productdetailtitle{
	font-size:20px;
	background: #fff;
	padding: 15px;
	border: 1px solid #d6dce0;
}
/* .img-responsive-h{
	margin:inherit !important;
} */
.chekoutstep5-table .col-md-3.ng-scope{
	width:25%;
}
.order-history{
	width:100%;
	float:left;
}
.order-history {
    font-size: 14px;
}
.prod-in-slider .owl-item .proHoverEffect{
	width:100%;
	padding:10px 0px 0px 0px !important;
}
.comboproductitems,.comboproductitems:hover {
    margin: 10px 0px !important;
    padding: 10px 10px !important;
    width: 98%;
	
	 border: 1px solid #d6dce0;
    box-shadow: none !important;
    transition: all 0.5s ease 0s;
}
.owl-carousel .owl-item:nth-of-type(2n+2) .comboproductitems{
	float:right;
}
.adresfloortxtcvr{
	width: 45%;
}
.adressunit{
	width: 44%;
}
.checkoutrighbuttonadd{
	width:40%;
}
.checkoutrighbuttoncancel{
	width:40%;
}
.alcohalfootersign{
	font-size: 14px;
    font-weight: 600;
    padding: 6px 49px;
}
.modal-footer.alcohalfotter{
	padding:10px 15px;
}

.no-image.prod_pic{
	background-size:auto;
}
.owluperpartcover .owl-item{
	text-align:center
}
.owluperpartcover .owl-item img{
	display:inline-block;
}
.productdetailbrudcumcover{
	padding:15px;
}
.cocktaildetail-cover .media-left{
	width:100%;
	padding-right:0px;
	height:inherit;
}
.cocktail-cartbtn{
	text-align:center;
}
.btn.product_addcart.addcart-detail{
	float: none;
    margin-bottom: 10px;
    margin-left: 0;
    margin-top: 10px;
}
.productdetailbrudcumcover iframe{
	width:100%;
	height:auto;
}
.product-detail-right-set{
	position:static;
}
.productaddedpopup {
    left: inherit;
    right: 0;
}
.productaddedpopup::before{
	left: inherit;
    right: 35px;
}
.alcohalpopuptext{
	float:none;
	display:inline-block;
}


.mob-popup-footer{
	position: relative;
	bottom: 0;
}



.modal-uifooter{
	width: 100%;
	padding: 0px;
	position: relative;
	bottom: 0;
	left: 0;
}

.md-dialog-container{
	height: 100% !important;
}

.inner-form{
	    width: 100%;
    float: left;
    height: 100%;
    min-height: 320px;
}

.modal-footer.alcohalfotter {
    border-radius: 0 0 0px 0px;
}

.register_checktext{
	width: 100%;
	text-align: justify;
}



/*#icon {
    height: 65px !important;
    margin: auto !important;
    width: auto !important;
}*/



md-dialog .md-dialog-content{
	padding-bottom: 60px !important;
}

.header {
	background-size: auto 850px;
}
.product-listcover2{
	padding: 0px;

}
.product-listcover2 .salediv,.combo_sliderProduct .salediv,.prod-in-slider .owl-item .salediv{
	display: none;

}
.product-listcover2 .cartbtn,.combo_sliderProduct .cartbtn,.prod-in-slider .owl-item .cartbtn{
	width: 100%;
	border-radius: 0px;
}
.product-listcover2 .prod_addtocart,.combo_sliderProduct .prod_addtocart,.prod-in-slider .owl-item .prod_addtocart{
	margin-bottom: 0px !important;
}
.innerpagemargintop .combo_slider .md-padding{
	padding: 0px !important;
}
.product-listcover2 .prod_pic,.combo_sliderProduct .prod_pic,.prod-in-slider .owl-item .prod_pic{
	padding: 0px 10px;
}
.combo_sliderProduct a.core-block,.combo_sliderProduct a.core-block,.prod-in-slider .owl-item a.core-block{
	text-align: center;
	padding:0px 10px 15px;
}
.combo_sliderProduct .prod_pic img,.prod-in-slider .owl-item .prod_pic img{
	max-width: 100%;
}
.combo_sliderProduct .prod_desc,.combo_sliderProduct .prod_price,.combo_sliderProduct .prod_discout_price,
.owl-item .prod_discout_price,.owl-item .prod_desc,.owl-item .prod_price{
	text-align: center;
}
.owl-item .prod_addtocart{
	float: left !important;
}
.combo_sliderProduct .prod_discout_price,.prod-in-slider .owl-item .prod_discout_price{
	font-size: 20px;
}
.combo_sliderProduct .addmore,.prod-in-slider .owl-item .addmore{
	padding: 0px 24px;
	line-height: inherit;

}
.comboproductitems .prod_desc,.prod-in-slider .owl-item .prod_desc{
	font-size: 14px;
}
.combo_sliderProduct .label.label-warning.ng-binding.ng-scope,.prod-in-slider .owl-item .label.label-warning.ng-binding.ng-scope{
	display: inline-block;
	float: none;
}
.combo_sliderProduct .prod_desc.ng-binding,.prod-in-slider .owl-item .prod_desc.ng-binding{
	  height: 34px;/*margin-bottom: 0;*/
}
.combo_sliderProduct{
	background:none;
	padding-top: 0px;
	border: none;
}
.combo_sliderProduct .md-tab,.prod-in-slider .owl-item .md-tab{
	font-size: 20px;

}
.combo_sliderProduct md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar{
	background: #aa00ff !important

}
.combo_sliderProduct md-tabs-wrapper,.prod-in-slider .owl-item md-tabs-wrapper{
	background: #fff !important;
	border-radius: 5px 5px 0px 0px;

}
.combo_sliderProduct .prod_pic img,.prod-in-slider .owl-item .prod_pic img{
	max-width: 100%;
}
.combo_sliderProduct .prod_chilled,.prod-in-slider .owl-item .prod_chilled{
	text-align: center;
}
.combo_sliderProduct .prod_chilled span,.prod-in-slider .owl-item .prod_chilled span{
	float: none;
	text-transform: uppercase;
}
.combo_sliderProduct .prod_chilled_icon, .prod-in-slider .owl-item .prod_chilled_icon{
	display: inline-block;
	float: none !important;
	vertical-align: middle;
}
.combo_sliderProduct .productsoldbt,.combo_sliderProduct .productsoldbt:hover,.prod-in-slider .owl-item .productsoldbt,,.prod-in-slider .owl-item .productsoldbt:hover{
	border-radius: 0px;
}
.product-listcover2 #toastBounds,.combo_sliderProduct #toastBounds,.prod-in-slider .owl-item #toastBounds{
	margin-bottom: 0px;
	width: 100%;
}
.combo_sliderProduct .productsoldbt img,.prod-in-slider .owl-item .productsoldbt img{
	float: none;
	margin-right: 5px;
}
.combo_sliderProduct .addcart span,.prod-in-slider .owl-item .cartbtn .addcart span{
	height: 24px;
    margin: 0 5px;
    vertical-align: middle;
    width: 19px;
    float: none;
}
.combo_sliderProduct .md-nav-bar,.prod-in-slider .owl-item .md-nav-bar{
	background: #fff !important;

}
.comboproductitems,.comboproductitems:hover {
    margin: 0px 0 10px !important;
    padding: 10px 0px 0px !important;
    width: 33.33%;
	background: #fff;
	 border: 1px solid #d6dce0;
    box-shadow: none !important;
    transition: all 0.5s ease 0s;
}
.desktop-showblock{
	display: none;
}
.responsive-blokimg{
	display: block;
}
.combo_sliderProduct .addmorehere,.prod-in-slider .owl-item .addmorehere{
	display: none;
}
.combo_sliderProduct .addmore-count,.prod-in-slider .owl-item .addmore-count{
	font-size: 20px;
	line-height: 30px;
}
.combo_sliderProduct .addmore .bootstrap-touchspin-down,.combo_sliderProduct .addmore .bootstrap-touchspin-up,
.prod-in-slider .owl-item .addmore .bootstrap-touchspin-down,.prod-in-slider .owl-item .addmore .bootstrap-touchspin-up{
	font-size: 20px;
}
.combo_sliderProduct .addmore .bootstrap-touchspin,.prod-in-slider .owl-item .addmore .bootstrap-touchspin{
	width: 100%;
	margin-left: 0px;
	height: 100%;
}
.combo_sliderProduct .md-button,.prod-in-slider .owl-item .md-button{
	font-size: 17px;
	text-transform: none;
}
.combo_sliderProduct .cartbtn,.prod-in-slider .owl-item .cartbtn{
	font-size: 13px;
}
.combo_sliderProduct .productsoldbt,.combo_sliderProduct .productsoldbt:hover,.prod-in-slider .owl-item .productsoldbt,.prod-in-slider .owl-item .productsoldbt:hover{
	font-size: 13px;
	border-radius: 0px;
	width: 100%;
}
.combo_sliderProduct .saletags-list,.prod-in-slider .owl-item .saletags-list{
	font-size: 12px;
}
.combo_sliderProduct .productbeerfiltertopcover,.prod-in-slider .owl-item .productbeerfiltertopcover{
	background: #fff;
	padding-top: 15px;
}
.combo_sliderProduct .tabbable .fullwidth{
	background: #fff;
	margin-top: 0px;
}
.congratulationpopuptext{
	width: 65% !important;
	padding-right: 0px;	
}
.product-bread-crumb{
  	text-align: left;

  }
/* .notify {
	box-shadow: 1px 1px 4px 4px #cccccc;
} */
.desktop-show-product-des{
	display: none;

}
.responsive-show-product-des{
	display: block;
}
.comboproductitems .salediv{
	display: none;
}
.comboproductitems .prod_addtocart{
	margin-bottom: 0px;
}
.comboproductitems .checkoutproductbutton{
	border-radius: 0px;
}
.comboproductitems .prod_pic img{
	max-width: 100%;
}
.comboproductitems .prod_desc,.comboproductitems .prod_price,.comboproductitems .prod_discout_price,{
	text-align: center;
}
.comboproductitems a.core-block{
	padding:0px 10px 15px;
	text-align: center;

}
.owl-item .comboproductitems,.owl-item .comboproductitems:hover{
	width: 100% !important;
}
.checkoutmiddleouter{
	padding: 0px 10px;
}
.single_slider .owl-theme .owl-controls{
	padding-bottom: 0px;
}
.productslide_normaltext.ng-binding {
	  max-height: 38px;
	  
  }
  .closemenu{
	display: block;

}

.closemenu{
	display: block;

}
.thumbnailheightset{
	text-align: center;
}
.item-btmtitle.ng-binding {
	height: 45px;
	overflow: hidden;
}
.notlinkmenu{
	pointer-events: none;

}
.prod-in-slider{
	padding: 20px 10px !important;
}
.prod-in-slider .owl-item  .cartbtn{
	width: 100%;
	border-radius: 0px;

}
.prod-in-slider .owl-item .salediv{
	display: none;
	
}
.dont-miss-footerhide{
	display: none;
}
.dont-miss-res-show{
	display: block;
}
.logos-inner-cover > a{
	display: inline-block;
}
.navbar-fixed-top .logos-inner-cover{
	width: 170px;
	display: inherit;
	margin-top: 5px;
}
md-toast.notify div.md-toast-content{
	width: 100%;
	float: right;
}
.comboproductitems, .comboproductitems{
	border-right: none !important;
}
.comboproductitems:nth-of-type(3n+3) , .comboproductitems:nth-of-type(3n+3):hover{
	border-right: 1px solid #d6dce0 !important;
}
.cartstepone-cover .owl-carousel .owl-item .comboproductitems:nth-of-type(2n+1),.cartstepone-cover .owl-carousel .owl-item .comboproductitems:nth-of-type(2n+1):hover{
	border-right: 1px solid #d6dce0 !important; 
}
.productcomboul{
	border-bottom: none !important;
}
.res-show{
	display: block;
}

.productbeerfiltertopcover .row div{
	margin-bottom: 0;
}

.cmspage-cover{
	padding: 0 15px 44px 15px;
}
.cms-rightpanelMobile{
	display: block;
}
.cms-rightpanel{
	display: none;
}
.checkoutmiddletable .wislistborbtm td,.checkoutmiddletable .wislistborbtm td:not([class="childtd"]){
	float: left;
	padding: 20px 5px !important;
	white-space: normal !important;
}
.wislistborbtm td:nth-child(1){
	width: 100%;
}
.wislistborbtm td:nth-child(3){
	width: 73%;
}
.wishlist-tbledatecover{
	padding: 0px !important;
	float: left;
}
.package-detail-new-box .grey-txt{
	width: 100%;
	float: left;
}
.package-detail-new-box .checkoutmiddlevlauediv{
	width: 70px !important;
}



.modify .dropdown-menu{
	min-height: auto;
}

.announcement-enable-wrapper .announcement-bar a{
	height: auto;
}

.announcement-container{
	position: static;
	top: 0;
	height: auto;
}

.announcement-enable-wrapper .announcement-bar a {
    /*display: table-cell;*/
    display: inline-block;
    text-align: center;
    width: 100%;
    float: none;
    /*height: 60px;*/
    vertical-align: middle;
}

.outer{
	display: inline-block;
}

.announcement-container img {
    max-height: 40px;
}

.announcement-enable-wrapper .announcement-bar{
	padding: 0;
}




.announcement-container span {
    max-width: 76%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    padding: 0 4px;
    font-style: 13px;
}

.announcement-container img {
    max-height: 42px;
}

.announcement-container {
    position: static;
    top: 0;
    height: auto;
    max-width: 90%;
}


.announcement-enable-wrapper .announcement-bar a {
    /*display: table-cell;*/
    display: inline-block;
    text-align: left;
    width: 100%;
    float: none;
    /*height: 60px;*/
    vertical-align: middle;
}


.outer {
    display: inline-block;
}

.announcement-enable-wrapper .announcement-bar{
	padding: 0;
}

.tag-purple-new-pos{
	position: static;
	margin-top: 10px;
}
.tag-lightyellow-new, .tag-yellow-new,.tag-purple-new{
	width: 100%;
	max-width: 100%;
}
.skew, .skew2{
	left: -77px;
}
.tag-purple-new-pos{
	width: 100%;
}
.nametag::after{
	top: 45% !important;
	left: 16px !important;
}
.salediscount::after{
	top: 45% !important;
	left: 16px !important;
}
.nametag,.salediscount{
	padding: 5px 5px 5px 35px !important;
}
.newfeature-text{
	background: #fff;
	padding-top: 10px;
	text-align: left;
	font-size: 20px;
}
}

@media only screen and (width : 640px) {
  .alcohal_addadress_headertitle {
	  font-size: 23px
  }

}

@media only screen and (max-width : 639px){
	 .addressbook-btmpannelcover .addressbokk-trvaichacovr:nth-child(3n){
	margin-right:10px;
}
.cartaddresses .addressbokk-trvaichacovr, .cartaddresses .addressbokk-addnewadresscovr{
	width:100%;
}
.comboproductitems,.comboproductitems:hover {
    margin: 0px 0 10px !important;
    padding: 10px 0px 0px !important;
    width: 33.33%;
	background: #fff;
	 border: 1px solid #d6dce0;
    box-shadow: none !important;
    transition: all 0.5s ease 0s;
}
md-tooltip{
	white-space: normal;
	font-size: 14px !important;
	width: 400px;
}
md-content.globalsearch md-autocomplete {
    width: 90% !important;
}

}

@media only screen and (max-width : 479px) {
  .col-md-2.homecallus_cover {
	  display: none;
  }
  .logolarge {
	  left: inherit;
	  width: 115px;
  }
  .signuplogin ul.menuulpadding > li:nth-child(2) {
	  display: none;
  }
  .signuplogin ul.menuulpadding > li:nth-child(1) {
	  display: none;
  }
  
  .signuplogin ul.menuulpadding > li:nth-child(4) {
	  display: table;
  }
  .signuplogin_cover {
	  width: 28%
  }
  .side-menu-responsive {
	  left: 0;
	  position: absolute;
	  top: 0;
	  z-index: 9999;
	  display: none
  }
  /*.navbar-collapse.nopadd.collapse.in {
			width: none;
		}*/
  .occassion_sale {
	  font-size: 70px;
	  float: left;
	  line-height: normal;
	  padding-bottom: 20px;
	  padding-top: 25px;
  }
  .tabtitle {
	  width: 100%
  }
  #bs-example-navbar-collapse-1 {
	  display: none
  }
  .item.ng-scope {
	  display: inline-block;
	  float: none;
	  margin: 0;
	  text-align: center;
	  width: 100%;
  }
  .item.ng-scope div {
	  /* display: inline-block;
	  float: none;
	  text-align: center; */
  }
  .registernowbtn.ng-scope {
	  margin: 20px 0;
  }
  .prod_pic {
	  margin-bottom: 15px;
  }
  .prod_desc.ng-binding {
	  /* height: auto;margin-bottom: 0; */
  }
  .prod_addtocart {
	  margin-bottom: 5px;
  }
  .prod_addtocart {
	  margin-bottom: 5px;
  }
  .offers {
	  width: 100%
  }
  .offrates {
	  width: 100%;
	  margin-bottom: 10px;
  }
  .happy_hours {
	  width: 100%
  }
  .fullwidth.ng-scope.header {
	  margin-bottom: 0;
  }
  .single_slider.ng-scope {
	  width: 100%;
	  padding: 20px 0px;
	  background-size: cover
  }
  .slido_right {
	  float: right;
	  font-size: 15px;
	  font-weight: 500;
	  margin-top: 10px;
	  padding: 0 20px;
	  width: 100%;
  }
  .single_slider.ng-scope .owl-carousel .owl-item {
	  width: 100%;
  }
  .slido_left {
	  margin-top: 0;
	  width: 100%;
  }
  .slido_right {
	  font-size: 15px;
	  font-weight: 500;
	  margin-top: 10px;
	  padding: 0 20px;
	  width: 100%;
  }
  .orderbooz {
	  margin-top: 20px
  }
  .socio_btns {
	  margin-top: 30px
  }
  .fullwidth.social_block {
	  padding: 20px 0;
	  text-align:center;
  }
  .socilblok_twtr {
	  margin-left: 0;
	  text-align: center;
	  width: auto;
	  float:none;
	  display:inline-block;
	  margin:15px 5px 5px 5px;
  }
  .socilblok_fb {
	  margin-left: 0;
	  text-align: center;
	  width: auto;
	  float:none;
	  display:inline-block;
	  margin:15px 5px 5px 5px;
  }
  .socilblok_folowtxt {
	  margin-left: 0;
	  text-align: center;
	  width: 100%;
  }
  .socilblok_twtr img {
	  display: inline-block;
	  float: none;
  }
  .socilblok_twtr > a > span {
	  display: inline-block;
	  float: none;
	  padding-left: 0;
  }
  .socilblok_fb img {
	  display: inline-block;
	  float: none;
  }
  .socilblok_fb > a > span {
	  display: inline-block;
	  float: none;
	  padding-left: 0;
  }
  .footer1_pannel1cvr {
	  width: 100%
  }
  .footer1_sociallink {
	  margin: 20px 0;
  }
  .footer1_privcytext {
	  width: 42%
  }
  .footer1_pannel2cvr {
	  width: 100%
  }
  .footer1_pannel3cvr {
	  width: 100%
  }
  .footer1_pannel4cvr {
	  width: 100%
  }
  .footer2_btmcvr {
	  margin-top: 20px
  }
  
 
  .leftphone {
	  max-width: 100%
  }
  .onthego_block {
	  background-size: cover
  }
  .dropdown-menu a:focus .nav.sidebar-nav li a {
	  background: none !important
  }
  .dropdown-menu a:hover .nav.sidebar-nav li a {
	  background: none !important
  }
  .dropdown-menu a:active .nav.sidebar-nav li a {
	  background: none !important
  }
  /*.ancontainer{
			z-index:2
		}*/
  .brands .owl-carousel .owl-item {
	  display: table-cell;
	  float: none;
	  max-width: 100%;
	  padding-bottom: 10px;
	  text-align: center;
	  vertical-align: middle;
  }
  .single_slider.ng-scope {
	  margin-bottom: 30px;
  }
  .searchtop100 {
	  right: 15px;
	  width: 88%;
  }
  .onthego {
	  font-size: 100px
  }
  .searchtop {
	  right: 26.5%;
  }
  .footer2_madetxt {
	  font-size: 25px;
	  margin-bottom: 0;
  }
 
  .searchtop.searchtop100 {
	  float: left;
	  margin-top: 0;
	  right: inherit;
	  width: 100%;
	  top: 35px
  }
  .ancontainer {
	  z-index: 9999
  }
  .searchopen .hamburger {
	  display: none
  }
  /*.currentorderpopup {
	  left: -188px;
	  width: 236px
  }
  .currentorderpopup::before {
	  left: 83%
  }*/
  .modal-dialog.alcohal_registermain {
	  text-align: center;
	  width: 93%;
	  float: none;
	  margin: 10px auto;
  }
  .alcohal_regisemailcvr {
	  width: 100%
  }
  .modal-footer.alcohalfotter {
	  padding: 20px 15px
  }
  .alcohallogin_forgettxt {
	  padding-bottom: 15px;
	  padding-top: 0;
	  text-align: center;
	  width: 100%;
  }
  .alcohalfootersign.alcohal_fotrloginbox {
	  display: inline-block;
	  float: none;
  }
  .gift-packagecoverdiv {
	  width: 100%;
	  margin: 0;
  }
  .modal-dialog.alcohal_addadressmain {
	  margin-left: 0;
	  margin-right: 0;
	  width: 100%;
	  margin-top: 0;
  }
  /*md-dialog {
	  max-height: 90%;
	  max-width: 90%;
	  overflow-y: auto;
	  overflow-x: hidden;
	  opacity: 1;
  }*/
  .alcohlinputextcvr {
	  margin-bottom: 0
  }
  .alcohlinputext {
	  width: 100%;
	  margin-bottom: 10px;
  }
  .alcohlinputrighttext {
	  width: 100%;
	  margin-bottom: 10px;
  }
  .alcohal_adress2fotter {
	  width: 51%
  }
  .modal-body.gift-packgng-bodydiv {
	  padding-left: 0;
	  padding-right: 0;
	  text-align: center;
  }
  .gift-item-cover {
	  display: inline-block;
	  float: none;
	  margin: 0 5px 15px auto;
  }
  .gift-packagecoverdiv {
	  background-size: initial
  }
  .gift-item-cover:last-child {
	  margin: 0 5px 15px auto;
  }
  .md-toolbar-tools.alcohal_addadress_header {
	  float: left;
	  height: auto;
	  margin-bottom: 0;
	  max-height: none;
  }
  /*.layout-row {
	  padding: 0;
	  padding-bottom: 20px;
	  padding-top: 15px;
  }*/
  .alcohal_addadress_headertitle {
	  font-size: 23px
  }
  .pushy.pushy-left {
	  display: block
  }
  #container {
	  display: block
  }
  .dropdown.cart-icon-set span {
	  display: none
  }
  .search_close.search_close_opaque {
	  line-height: 18px;
	  top: 0;
	  margin-top: 0
  }
  .searchtop.searchtop100 .search_icon {
	  left: 7px;
	  top: 0;
  }
  /*.layout-row {
	  padding: 0;
	  height: 40px;
  }*/
  .search_icon {
	  margin-right: 0
  }
  .logolarge {
	  left: inherit;
	  width: 115px;
  }
  .navbar-fixed-top.navbar-shrink .text-center .logosmall {
  }
  md-content.globalsearch md-autocomplete {
	  margin: 0;
	  margin-left: 10px
  }
  /*.layout, .layout-column, .layout-row {
  width:92.3%
  }
  .layout-row {
	  width: 95%;
  }*/
  .top-logo-sideacnt-cover {
	  padding: 0
  }
  .hamburger.is-closed {
	  margin-left: 4px;
  }
  .search_close, .search_close:hover, .search_close:focus {
	  margin-top: 0;
	  line-height: 18px;
  }
  .img-responsive.logolarge {
	  top: 0
  }
  .profile_form .registeremailcvr {
	  padding-right: 0;
	  width: 100%
  }
  .change_form .registeremailcvr.paswrd-width-set5 {
	  float: left;
	  margin-right: 0;
	  width: 100%;
  }
  .addressbokk-trvaichacovr.ng-scope {
	  margin: 0 0 20px;
	  width: 100%;
  }
  .addressbokk-addnewadresscovr {
	  margin: 0;
	  width: 100%;
  }
  .cartaddresses .addressbokk-trvaichacovr, .cartaddresses .addressbokk-addnewadresscovr{
	  width: 100%;
	 }
  .addressbook-btmpannelcover {
	  margin-bottom: 10px
  }
  .balancedetailsdivinner {
	  margin-right: 38px;
  }
  .balancedetailsdiv {
	  margin-bottom: 30px
  }
  .orhistry2_td2proctext1 {
	  float: left;
	  width: 100%;
  }
  .checkouter-td-right-text {
	  float: none;
	  margin-right: 20px;
	  width: 73%;
	  display: inline-block
  }
  .checkboxtotaldiv-title {
	  width: 65%
  }
  .checkout-product .col-md-3.col-sm-3.col-xs-12.m-bottom-20 {
	  width: 100%
  }
  /*.steps_notify .step_grid a {
			border-radius: 50%;
			display: inline-block;
			height: 60px;
			position: absolute;
			top: -18px;
			white-space: normal;
			width: 20px;
			word-wrap: break-word;
			float:none
		}
		.step1 {
			left: 31px;
		}
		.step2 {
			left: 88px;
			top: -32px !important;
		}
		.steps_notify .step_grid a span
		{
			width:auto
		}
		.step3 {
			left: 146px;
			top: -32px !important;
		}
		.step4 {
			left: 206px;
			top: -32px !important;
		}
		.step5 {
		   float: none !important;
			right: 4px !important;
			width: 22px !important;
		}
		.steps_notify {
			float: left;
			margin: 30px 0 45px;
			min-height: 60px;
			position: relative;
			width: 100%;
		}
		.steps_notify .step_grid {
			position: absolute;
		}*/
  .div_addsboxs.ng-scope {
	  margin: 0 0 20px;
	  width: 100%;
	  margin-right: 0
  }
  .add_alltext {
	  width: 69%
  }
  .single-slot {
	  font-size: 12px;
	  white-space: normal;
  }
  .slots-bunch-title .week-stamp {
	  font-size: 1em
  }
  .cover_cashdiv_left.paymenttype.active {
	  width: 100%;
  }
  .cover_cashdiv_left{
  	width: 100%	
  }
  .cover_creadetcard_right {
	  width: 100%
  }
  .checkoutstep5-left-first-left {
	  width: 87%
  }
  .checkouterwantbutton {
	  width: 100%;
  }
  button.checkoutergitpackage {
	  width: 100%
  }
  .steps_notify .step_grid a span {
	  top: 45px
  }
  .checkouterback {
	  margin-bottom: 10px;
	  text-align: center;
	  width: 100%;
  }
  .checkouterbackouter {
	  text-align: center
  }
  .add-newaddbox {
	  width: 100%;
	  margin-top: 10px;
  }
  .slots-bunch-title .date-stamp {
	  text-transform: uppercase;
	  text-align: center;
	  font-size: 0.9em;
	  word-wrap: break-word;
	  white-space: normal;
	  width: 40px;
  }
  .checkoutstepthree_showdate {
	  margin-bottom: 0;
	  font-size: 21px;
  }
  .checkoutcheckoutnowbutton2 {
	  min-width: 150px;
  }
  md-datepicker {
	  margin-right: 0
  }
  .md-button.md-icon-button {
	  margin-right: 0;
	  margin-left: 0;
  }
  .sharepurchasecover {
	  font-size: 20px
  }
  .alcohal_reapeatorederbtnsocvercvr {
	  margin: 0 auto;
	  padding: 0 10px 20px;
	  width: 100%;
  }
  .alcohal_hisryorderboxcvr1.ng-scope {
	  margin-right: 0;
  }
  .alcohal_hisryorderboxcvr1 {
	  width: 100%
  }
  .prddetailtitle.ng-binding {
	  font-size: 22px;
  }
  .checkoutmiddlevlauediv {
	  display: inline-block;
	  float: none;
	  width: 50%;
  }
  .prdquantitybulkcoverbulk {
	  display: inline-block;
	  float: none;
	  width: 100%;
  }
  .alcohal_reapeatorenothnxtxt {
	  float: right;
	  margin-right: 7px;
  }
  .alcohal_orderhistorybodytxt {
	  width: 100%
  }
  .alcohal_orderhistory2fotter {
	  width: 100%;
	  text-align: center;
  }
  .alcohalfooter_shopordershopbtn {
	  margin-bottom: 0
  }
  .productinner_leftslide.left-main-outerdiv {
	  width: 100%;
	  padding:0px 15px;
  }
  .productslide_normaltext{
	  white-space:normal;
	 }
  .btn.product_addcart.addcart-margin-responsive {
	  margin-top: 0
  }
  .owl-wraaper-margin .owl-wrapper {
	  margin-bottom: 33px;
  }
  .left-main-outerdiv .productslide_heading.ng-binding {
	  font-size: 22px;
	  line-height: 30px;
	  height: auto;
	  overflow: visible
  }
  .dropdown.productdropdownbtn {
	  margin-right: 15px;
	  margin-top: 12px;
	  width: auto;
  }
  .dropdown.productdropdownbtn span1 {
	  float: left;
	  position: relative;
	  right: 0;
  }
  .productslide_normaltext.ng-binding {
	  max-height: 38px;
	  
  }
  .comboproductitems {
	  width: 100%
  }
  .productbeerfiltertopcover .row div {
	  width: 100%
  }
  .product-bread-crumb{
  	text-align: left;

  }
  /*.md-dialog-content.alcohaladdadrebody {
			padding: 54px 24px 24px;
		}*/
  .alcohal_addadress_headertitle span {
	  /*width: 82%;
	  display: inline-block;
	  font-size: 15px;*/
  }
  .alcohal_addadress_headertitle {
	  font-size: 18px
  }
  /*.alcohal_addadress_headercurcle {
	  margin-top: -8px;
  }*/
  .alcohalorderhisttitle {
	  font-size: 18px
  }
  .currentorderpopuppinkboxleft {
	  width: 126px
  }
  .currentorderpopup::before {
	  top: -8px
  }
  .prdquanbulkboxone {
	  width: 54px;
  }
  .product-detail-right-set {
	  padding-left: 15px;
	  padding-right: 15px;
  }
  .prdquanbulkboxtwo {
	  width: 64px
  }
  .alcohlinputextarea1 {
	  width: 100%
  }
  .adressunit {
	  width: 44%
  }
  .adresfloortxtcvr {
	  width: 44%
  }
  .left-party-content {
	  width: 100%
  }
  .part-content-btm-desc {
	  padding-left: 15px;
	  padding-right: 15px;
  }
  .btmcontn-set a {
	  width: 25%;
	  margin: 2px 0;
	  font-size: 11px;
  }
  .package-desc {
	  width: 75%
  }
  .saving-amount-cover {
	  float: left;
	  padding-left: 0;
	  padding-top: 3px;
	  width: 45%;
  }
  .package-amtcover {
	  float: left;
	  width: 55%;
  }
  .add-cart-cover {
	  float: left;
	  width: 100%;
  }
  .customize-text-cover > span {
	  padding-left: 4px;
  }
  .title-propertset {
	  font-size: 21px;
	  margin-top: 15px;
  }
  .media-body.party-detail-contentcover {
	  padding-left: 0;
  }
  .party-detail-heading {
	  font-size: 24px
  }
  .party-detail-btmtitl4 > h4 {
	  font-size: 16px;
  }
  .ttile-divcover.party-deatil-pading5.ng-scope {
	  padding-left: 4px;
  }
  .media-body.cockatil-bodycover {
	  padding-left: 0;
	  width: 100%;
  }
  .moredetail-cocktailtext > a {
	  font-size: 14px;
	  text-align:left;
	  margin: 2px 0;
	  width: 100%;
  }
  .product-desccocktail.ng-binding {
	  width: 100%;
	  max-width:100%;
	  font-size:14px;
  }
  /*.cocktail-cartbtn{
			text-align:center
		}
		.btn.product_addcart.addcart-detail.cocktail-cart-margintop {
			display: inline-block;
			float: none;
			margin-left: 0;
			text-align: center;
		}*/
  .cocktail-amntdivcover {
	  float: left;
	  text-align: center;
	  width: auto;
  }
  .saving-cocktail-cover span {
	  width: auto
  }
  .saving-titie-amnt {
	  width: auto
  }
  .cocktail-detail-width {
	  width: 100%;
	  float: left;
	  padding: 0 10px;
  }
  /*md-tabs-canvas.md-paginated {
	  margin: 0 auto;
	  width: 133px;
  }*/
  /*.md-tab {
	  min-width: 133px;
	  text-align: center;
  }*/
  .media-body.cockatil-bodycover {
	  padding-left: 0;
	  width: 100%;
  }
  .media-left.cocktail-leftimgdiv {
	  width: 100%;
	  height: 100%;
	  padding-right:0px;
  }
  .title-propertset.ng-binding {
	  margin-top: 15px
  }
  .party-detail-btm-width {
	  width: 100%
  }
  .navbar-shrink .dropdown.cart-icon-set span, .navbar-shrink .dropdown.cart-icon-set span:hover, .navbar-shrink .dropdown.cart-icon-set span:focus {
	  display: none
  }
  .yelo_btn {
	  padding: 6px 20px;
  }
  .cocktaildetail-cover .media-left {
	  float: left;
	  height: 100%;
	  padding-right: 0;
	  width: 100%;
  }
  .gift-card-innerform {
	  width: 100%
  }
  .tabsright {
	  width: 74%
  }
  .tabtitle {
	  padding-bottom: 10px;
  }
  .dropdown.productdropdownbtn {
	  width: 155px;
	  margin-right: 0;
	  margin-top: 0;
  }
  .dropdown.productdropdownbtn.open {
	  left: 0;
	  right: 0;
	  width: 155px;
  }
  .nav.nav-tabs.productcomboul {
	  padding-left: 0;
  }
  .rightplcholder {
	  display: none;
  }
  .searchtop.searchtop100 {
	  top: 39px
  }
.checkouter-td-right-text{
	width:64%;
	margin-left:10px;
}
.checkboxtotaldiv-middle{
	padding:15px 0px;
}
.step-progressbar{
	top:20px;
}
.step-individual{
	width:50px;
	height:50px;
	background-size:75%;
}
.signuplogin a, .signuplogin > a:hover, .signuplogin a:focus{
	padding:40px 15px 60px !important;
}
.cart_icon{
	min-width:35px;
}
#cart-summary-icon{
	display:block !important;
}
.alcohlchekboxdiv12 md-checkbox{
	float:left;
}
.alcohlchekboxdiv12 > label {
    float: left;
    width: 87%;
}
.dropdown.cart-icon-set span {
    display: none !important;
}
.checkoutstep5right-bottom-title{
	width:70%;
}
.amntrupee{
	
	text-align:left;
}
.alcohalorderhisttitle {
    font-size: 16px;
}
.combo_slider .nav-tabs li a{
	padding:10px;
}
.productaddedpopup{
	left:inherit;
	right:0px;
}
.productaddedpopup::before{
	left:inherit;
	right:10px;
}
.alcohal_addadress_headertitle {
    font-size: 18px;
	
}
 
.currentorderpopup::before{
	right:10px;
}
.thumbnail.cocktail-products-div{
	width:100%;
	
}

.prod-in-slider .owl-item .proHoverEffect{
	width:100%;
	margin:0px 0px 10px !important;
	padding: 10px 0px 0px !important;
}

.comboproductitems,.comboproductitems:hover {
    margin: 0px 0 10px !important;
    padding: 10px 0px 0px !important;
    width: 50%;
	background: #fff;
	 border: 1px solid #d6dce0;
    box-shadow: none !important;
    transition: all 0.5s ease 0s;
}
.md-button{
	min-width: 50px !important;
	padding: 12px 7px !important;
}
.comboproductdetailitems{
	width: 50%;
}
.slots-bunch-title{
	min-height: 70px;
}
md-tooltip{
	white-space: normal;
	font-size: 14px !important;
	width: auto;
}

.ro_desc{
	width: 70%;
}
.closemenu{
	display: block;

}
.comboproductitems, .comboproductitems{
	border-right: 1px solid #d6dce0 !important;
}
.comboproductitems:nth-of-type(2n+1) , .comboproductitems:nth-of-type(2n+1):hover{
	 border-right: none !important; 
}
.cartstepone-cover .owl-carousel .owl-item .comboproductitems:nth-of-type(2n+1),.cartstepone-cover .owl-carousel .owl-item .comboproductitems:nth-of-type(2n+1):hover{
	border-right: 1px solid #d6dce0 !important; 
}
md-tabs.md-dynamic-height md-tabs-content-wrapper{
top: 1px;
}
.wislistborbtm td:nth-child(3) {
    width: 58%;
}
.wislistborbtm td:nth-child(2) {
    width: 33%;
}
.wislistborbtm td:nth-child(4) {
    width: 9%;
}

}

@media only screen and (max-width : 479px) {
.combo_slider .nav-tabs li a{
	padding:10px 5px;
}
.dropdown.productdropdownbtn.open {
    left: 0;
    right: 0;
    width: 180px;
}
.dropdown.productdropdownbtn {
    left: 0;
    right: 0;
    width: 180px;
}

.party-detail-heading{
	font-size:20px;
}
.alcohal_adress2fotter{
	font-size:12px;
}
.additempopuppinkboxrightinner a,.additempopuppinkboxrightinner a:hover,.additempopuppinkboxrightinner a:focus{
	padding:0px !important;
}
.announcement-container span{
	font-size: 12px;
	max-width: 72%;
	display: inline-block;
	vertical-align: middle;
}
md-content.globalsearch md-autocomplete {
    width: 85% !important;
}
.package-detail-new-box .grey-txt{
	width: 100%;
	float: left;
}
.package-detail-new-box .checkoutmiddlevlauediv{
	width: 70px !important;
}



.productbeer-headtitleouter .productbeerfiltertoptitle{
	width: 100%;
	margin-bottom:10px; 
}

.productbeer-headtitleouter .productbeerfiltertoptitle.productbeer-point-rw{
	float: left;
}

.innerpagemargintop p{
	text-align: center;
}



.announcement-container img {
    max-height: 37px;
}
.cart-table td{
	float: left;
	border: none !important;
}
.cart-table tr{
	border-bottom: 1px solid #ddd;
}
.cart-table tr:last-child{
	border-bottom: none;
}
.checkoutmiddletabledeleteimg, .checkoutmiddletabledeleteimg:hover, .checkoutmiddletabledeleteimg:focus{
	margin-top: 5px;
}
.cart-table td:nth-child(3){
	margin-top: -10px;
}
.checkoutmiddletable table.cart-table td:nth-child(2){
	min-height: 68px;
    text-align: left;
    width: 70%;
    padding-top: 5px !important;
}
.cart-table td:nth-child(2) .prod_chilled{
	text-align: left;
}
.cart-table td:nth-child(3){
	float: right;
    width: 50%;
}
.cart-table td:nth-child(3) .checkoutmiddlevlauediv{
	float: right;
}
.checkoutmiddletable table.cart-table td:nth-child(4){
	text-align: left;
    width: 30%;
    padding-bottom: 0px !important;
}
.cart-table td:nth-child(4) .orderhistry2price{
	float: left;

}
.checkoutmiddletable table.cart-table td:nth-child(5) {
    float: right;
    text-align: right;
    width: 30%;
    padding-top: 10px !important;
}

.cart-table td:nth-child(5) .glyphicon {
	font-size: 20px;
}
.responsive-show{
	display: block;
}
.desk-show{
	display: none;
}
.respoonsive-show-chilled{
	width: 100%;
	display: block;
	float: left;
	margin-bottom: 10px;
}
.checkoutmiddletable table td.res-deleteicon-cart{
	background: #eee none repeat scroll 0 0;
    border-top: 2px solid #fff !important;
    border-bottom: 2px solid #fff !important;
    display: table-cell;
    float: none !important;
}
.checkoutmiddletable .orhistry2_td2procdtcvr > span{
	width: 45px;
	height: 45px;
}
.cart-table td:nth-child(2) .checkouter-td-right-text{
	width: 63%;
	margin-right: 0px;
}
.cart-table td:nth-child(4) .checkoutmiddlevlauediv{
	width: 100%;
}
.res-deleteicon-cart i{
	font-size: 16px;
	color: #333;
}
.respoonsive-show-chilled .prod_chilled span{
	font-size: 12px;
}
.orderhistry2price{
	font-size: 14px;
}
.orhistry2_td2proctext1{
	font-size: 13px;
}
.sale-tag-txt.-cart{
	font-size: 12px !important;
}
.sale-pro-list li {
    height: 40px !important;
    margin: 0 !important;
    padding: 2px !important;
    width: 31px !important;
}
.sale-pro-list li.sale-action-add-icon {
    border-bottom: medium none;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 15px !important;
}
.checkoutmiddletable{
	margin-top: 10px;
}
.gift-pack-products li {
    
    height: 45px !important;
    margin: 10px 22px 0 5px !important;
    padding: 5px !important;
    position: relative;
    text-align: center;
    width: 50px !important;
}
.qty-tag-hang {
    border-radius: 50%;
    color: #000;
    font-size: 12px;
    height: 20px;
    padding: 2px 0 0;
    position: absolute;
    right: -23px !important;
    top: 32% !important;
    white-space: nowrap;
    width: 20px;
}
.checkoutmiddleouter{
	padding: 0px 0px;
}
.checkbox-gray-out{
	padding:0px 10px;
}
.res-pad-in{
	padding:0px 10px;
	margin-top: 25px;
}
.cartstepone-cover .owl-carousel .owl-item{
	width: 164px !important;
}
}


@media only screen and (max-width : 340px) {

.announcement-container img {
    max-height: 30px;
}
.cartstepone-cover .owl-carousel .owl-item{
	width: 144px !important;
}
}